import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosResponse } from "axios"

import {
  InitiativesRequests,
  InitiativesResponses,
} from "@costory/types/endpoints/initiatives"

import { apiClient } from "@costory/front/lib/apiClient"
import { queryClient } from "@costory/front/lib/queryClient"

const getInitiativesList = async () => {
  const response =
    await apiClient.get<InitiativesResponses.InitiativesListPage>(
      "/initiatives",
    )
  return response.data
}

export const useInitiativesListPageQuery = () => {
  return useQuery<InitiativesResponses.InitiativesListPage>({
    queryKey: ["initiatives"],
    queryFn: getInitiativesList,
  })
}

const getInitiative = async (id: string) => {
  const response = await apiClient.get<InitiativesResponses.GetInitiative>(
    `/initiatives/${id}`,
  )
  return response.data
}

export const useGetInitiativeQuery = (id: string) => {
  return useQuery<InitiativesResponses.GetInitiative>({
    queryKey: ["initiatives", id],
    queryFn: () => getInitiative(id),
  })
}

const getInitiativeFormData = async (savedViewId: string) => {
  const response = await apiClient.get<InitiativesResponses.GetFormData>(
    "/initiatives/form-data?saved-view-id=" + savedViewId,
  )
  return response.data
}

export const useInitiativeFormDataQuery = (savedViewId: string) => {
  return useQuery<InitiativesResponses.GetFormData>({
    queryKey: ["initiatives", "form-data", savedViewId],
    queryFn: () => getInitiativeFormData(savedViewId),
  })
}

export const getInitiativeCostData = async (
  params: InitiativesRequests.GetInitiativeSavingsOverviewData,
) => {
  const response = await apiClient.post<
    InitiativesResponses.GetInitiativeSavingsOverviewData,
    AxiosResponse<InitiativesResponses.GetInitiativeSavingsOverviewData>,
    InitiativesRequests.GetInitiativeSavingsOverviewData
  >("/initiatives/cost-data", params)
  return response.data
}

export const useInitiativeSavingsOverviewData = ({
  savedViewId,
  startDate,
  createdAt,
  expectedSavings,
}: InitiativesRequests.GetInitiativeSavingsOverviewData) => {
  return useQuery<InitiativesResponses.GetInitiativeSavingsOverviewData>({
    queryKey: [
      "initiatives",
      "cost-data",
      savedViewId,
      startDate,
      expectedSavings,
    ],
    queryFn: () =>
      getInitiativeCostData({
        savedViewId,
        startDate,
        createdAt,
        expectedSavings,
      }),
  })
}

const deleteInitiative = async (id: string) => {
  const res = await apiClient.delete<InitiativesResponses.DeleteInitiative>(
    `/initiatives/${id}`,
  )
  return res.data
}

export const useDeleteInitiativeMutation = () => {
  return useMutation({
    mutationFn: deleteInitiative,
    onSuccess: async (deletedInitiative) => {
      await queryClient.invalidateQueries({
        queryKey: ["saved-views", deletedInitiative.savedViewId],
      })
      await queryClient.invalidateQueries({ queryKey: ["initiatives"] })
    },
  })
}

const createInitiative = async (data: InitiativesRequests.CreateInitiative) => {
  const response = await apiClient.post<InitiativesResponses.CreateInitiative>(
    "/initiatives",
    data,
  )
  return response.data
}

export const useCreateInitiativeMutation = () => {
  return useMutation({
    mutationFn: createInitiative,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: ["saved-views", data.savedViewId],
      })
      await queryClient.invalidateQueries({ queryKey: ["initiatives"] })
    },
  })
}

const editInitiative = async ({
  id,
  ...payload
}: InitiativesRequests.EditInitiative) => {
  const response = await apiClient.put<InitiativesResponses.CreateInitiative>(
    `/initiatives/${id}`,
    payload,
  )
  return response.data
}

export const useEditInitiativeMutation = () => {
  return useMutation({
    mutationFn: editInitiative,
    onSuccess: async () =>
      await queryClient.invalidateQueries({ queryKey: ["initiatives"] }),
  })
}
