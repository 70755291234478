import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import _ from "lodash"

import { TreeNode, TreeNodeType } from "@costory/types/endpoints/folders"

import { queryClient } from "@costory/front/lib/queryClient"
import {
  createFolder,
  deleteFolder,
  fetchFolderContent,
  fetchFolderTree,
  moveFile,
  renameFolder,
  searchFolder,
  updateFolder,
} from "@costory/front/services/foldersService"

export const useFolderContent = (id: string | null) => {
  return useQuery({
    queryKey: ["folders", id],
    queryFn: () => fetchFolderContent(id!),
    enabled: id !== null,
  })
}

export const prefetchFolderContent = (id: string) => {
  return queryClient.prefetchQuery({
    queryKey: ["folders", id],
    queryFn: () => fetchFolderContent(id),
  })
}

export const useFolderTree = () => {
  return useQuery({
    queryKey: ["folders", "tree"],
    queryFn: () => fetchFolderTree(),
  })
}

export const useCreateFolder = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ["createFolder"],
    mutationFn: createFolder,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["folders", "tree"] })
    },
  })
}

export const useUpdateFolder = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ["updateFolder"],
    mutationFn: updateFolder,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["folders", "tree"] })
    },
  })
}

export const useDeleteFolder = () =>
  useMutation({
    mutationKey: ["deleteFolder"],
    mutationFn: deleteFolder,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["folders", "tree"] })
      await queryClient.invalidateQueries({
        queryKey: ["folders", data.folderId],
      })
    },
  })

export const useFoldersSearch = (search: string) =>
  useQuery({
    queryKey: ["folders", "search", search],
    queryFn: () => searchFolder(search),
  })

export const useRenameFolder = () =>
  useMutation({
    mutationFn: ({ id, name }: { id: string; name: string }) =>
      renameFolder({ id, name }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["folders"] })
    },
  })

export const useMoveFileMutation = () =>
  useMutation({
    mutationFn: ({
      fileId,
      type,
      folderId,
    }: {
      fileId: string
      type: "dashboard" | "savedView"
      folderId: string
    }) => moveFile(fileId, type, folderId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["folders"] })
    },
  })

export const sortTree = (nodes: TreeNode[]): TreeNode[] => {
  return _.sortBy(
    _.map(nodes, (node) => {
      if (_.has(node, "children") && _.isArray(node.children)) {
        node.children = sortTree(node.children)
      }
      return node
    }),
    (node) => (node.nodeProps?.type === TreeNodeType.Folder ? 0 : 1),
  )
}
