import { Button, Group, Stack } from "@mantine/core"
import { IconEdit } from "@tabler/icons-react"
import { Link, useParams } from "react-router-dom"

import { useUser } from "@costory/front/components/auth/UserContext"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { BudgetCSVExportDownloadButton } from "@costory/front/pages/Budgets/components/BudgetCSVExportButton"
import { BudgetTable } from "@costory/front/pages/Budgets/components/BudgetTable"
import { BudgetTableMode } from "@costory/front/pages/Budgets/consts"
import { useGetBudgetQuery } from "@costory/front/queries/budgets"

export const BudgetPage = () => {
  const { budgetId } = useParams<{ budgetId: string }>()
  const query = useGetBudgetQuery(budgetId!)
  const user = useUser()
  return (
    <QueryWrapper query={query}>
      {({ data: { versions, formData } }) => (
        <Stack>
          <BudgetTable
            formData={formData}
            versions={versions}
            mode={BudgetTableMode.View}
          />
          <Group justify="end">
            <BudgetCSVExportDownloadButton budgetId={versions[0].versionId!} />
            {versions[0].createdBy.id === user.id && (
              <Button
                component={Link}
                to="edit"
                leftSection={<IconEdit />}
                variant="outline"
              >
                Edit
              </Button>
            )}
          </Group>
        </Stack>
      )}
    </QueryWrapper>
  )
}
