import { NumberInput } from "@mantine/core"
import _ from "lodash"

import { Currency } from "@costory/types/prisma-client"

import { FormatedTd } from "@costory/front/pages/Budgets/components/FormatedTd"
import { SectionHead } from "@costory/front/pages/Budgets/components/SectionHead"
import { mediumGrayBorder } from "@costory/front/pages/Budgets/consts"
import { sumYTDOrNull } from "@costory/front/pages/Budgets/utils"

export interface AllocationsRowProps {
  allocations: number[]
  onChange: (allocations: number[]) => void
  currency: Currency
  name: string
}

export const AllocationsRow = ({
  allocations,
  onChange,
  currency,
  name,
}: AllocationsRowProps) => {
  const onCellChange = (value: number, index: number) => {
    const newAllocations = [
      ...allocations.slice(0, index),
      value,
      ...allocations.slice(index + 1),
    ]

    onChange(newAllocations)
  }

  const budgetYTD = sumYTDOrNull(allocations)
  const budgetTotal = _.sum(allocations)

  return (
    <>
      <SectionHead name={name} />
      {allocations.map((allocation, index) => (
        <td
          key={index}
          style={{
            borderRight: index === 11 ? mediumGrayBorder : undefined,
            padding: 4,
          }}
        >
          <NumberInput
            allowLeadingZeros={false}
            suffix={currency === "EUR" ? "€" : "$"}
            variant="unstyled"
            bg="indigo.1"
            styles={{
              root: {
                borderRadius: 8,
              },
              input: {
                textAlign: "center",
              },
            }}
            hideControls
            value={allocation}
            onChange={(value) => {
              onCellChange(typeof value === "number" ? value : 0, index)
            }}
          />
        </td>
      ))}
      <FormatedTd
        style={{ borderRight: mediumGrayBorder }}
        data={budgetYTD}
        currency={currency}
      />
      <FormatedTd
        style={{ borderRight: mediumGrayBorder }}
        data={budgetTotal}
        currency={currency}
      />
    </>
  )
}
