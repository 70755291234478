import { BarChart } from "@mantine/charts"
import {
  Box,
  Group,
  InputWrapper,
  NumberInput,
  Paper,
  Space,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core"
import { MonthPickerInput } from "@mantine/dates"
import { UseFormReturnType } from "@mantine/form"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import _ from "lodash"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { useMemo } from "react"

import {
  InitiativesRequests,
  InitiativesResponses,
} from "@costory/types/endpoints/initiatives"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { NumberCell } from "@costory/front/components/table/NumberCell"
import { useInitiativeSavingsOverviewData } from "@costory/front/queries/initiatives"
import { getCharteSerieColor } from "@costory/front/utils/charts"
import { formatNumber } from "@costory/front/utils/format"
import { PropsWithData } from "@costory/front/utils/propsWithData"
import { getDefaultTableOptions } from "@costory/front/utils/table"

dayjs.extend(utc)
interface SavingsOverviewProps {
  isReadOnly: boolean
  form: UseFormReturnType<InitiativesRequests.CreateInitiative>
  savedViewId: string
  startDate: Date
  createdAt: Date
  expectedSavings: number
  snoozeDate: Date | null
  snoozeValue: number | null
}

const _SavingsOverview = ({
  isReadOnly,
  form,
  data,
  startDate,
  createdAt,
  //snoozeDate,
  //snoozeValue,
}: SavingsOverviewProps &
  PropsWithData<InitiativesResponses.GetInitiativeSavingsOverviewData>) => {
  const { chartData, forecast, actuals } = data
  //Generate the series object for all values in actuals
  const series = _(chartData)
    .filter((t) => dayjs.utc(t!.agg_date) < dayjs().utc().subtract(1, "month"))
    .map((t) => _.omit(t, ["forecastedCost", "forecastedCostAfterSavings"]))
    .map((t) => _.keys(t))
    .flatten()
    .uniq()
    .map((key, index) => {
      if (key !== "agg_date") {
        return { name: key, color: getCharteSerieColor(index) }
      } else {
        return null
      }
    })
    .filter((t) => t !== null)
    .value()
  const createdAtReferenceLine = dayjs
    .utc(createdAt)
    .endOf("day")
    .format("MMM YYYY")
  const startDateReferenceLine = dayjs
    .utc(startDate)
    .add(1, "month")
    .startOf("month")
    .format("MMM YYYY")
  // const snoozeReferenceline = snoozeDate
  //   ? {
  //       label: "Snooze until",
  //       labelPosition: "right",
  //       x: dayjs(snoozeDate).format("MMM YYYY"),
  //       strokeWidth: 1,
  //       stroke: "var(--mantine-color-primary-6)",
  //     }
  //   : snoozeValue
  //     ? {
  //         label: "Snooze until",
  //         labelPosition: "top",
  //         position: "end",
  //         y: snoozeValue,
  //         strokeWidth: 1,
  //         stroke: "var(--mantine-color-primary-6)",
  //       }
  //     : undefined

  const columns = useMemo(() => {
    const columnHelper =
      createMRTColumnHelper<InitiativesResponses.tableSummary>()
    return [
      columnHelper.accessor("label", {
        header: "Label",
        Cell: ({ row }) =>
          row.original.tooltip ? (
            <Tooltip label={row.original.tooltip}>
              <Text style={{ cursor: "pointer" }}>{row.original.label}</Text>
            </Tooltip>
          ) : (
            <Text>{row.original.label}</Text>
          ),
      }),
      columnHelper.accessor("cost", {
        header: "Forecast",
        Cell: ({ row }) =>
          row.original.isSavings ? (
            <Box
              c={row.original.cost > 0 ? "green.6" : "red.6"}
              style={{ cursor: "pointer" }}
            >
              <NumberCell value={row.original.cost} currency="USD" />
            </Box>
          ) : (
            <NumberCell value={row.original.cost} currency="USD" />
          ),
      }),
      columnHelper.accessor("delta", {
        header: "Delta",
        Cell: ({ cell }) =>
          cell.getValue() ? (
            <Box
              c={cell.getValue()! < 0 ? "green.6" : "red.6"}
              style={{ cursor: "pointer" }}
            >
              <NumberCell value={cell.getValue()!} currency="USD" />
            </Box>
          ) : (
            ""
          ),
      }),
    ]
  }, [])
  const columnsActuals = useMemo(() => {
    const columnHelper =
      createMRTColumnHelper<InitiativesResponses.tableSummary>()
    return [
      columnHelper.accessor("label", {
        header: "Label",
        Cell: ({ row }) =>
          row.original.tooltip ? (
            <Tooltip label={row.original.tooltip}>
              <Text style={{ cursor: "pointer" }}>{row.original.label}</Text>
            </Tooltip>
          ) : (
            <Text>{row.original.label}</Text>
          ),
      }),
      columnHelper.accessor("cost", {
        header: "Actuals",
        Cell: ({ cell }) => (
          <NumberCell value={cell.getValue()} currency="USD" />
        ),
      }),
      // columnHelper.accessor("delta", {
      //   header: "Delta",
      //   Cell: ({ cell }) => (
      //     <NumberCell value={cell.getValue()} currency="USD" />
      //   ),
      // }),
    ]
  }, [])

  const forecastTable = useMantineReactTable<InitiativesResponses.tableSummary>(
    {
      data: _.filter(forecast, (t) => !t.hidden),
      columns,
      enablePagination: false,
      ...getDefaultTableOptions(),
    },
  )
  const actualsTable = useMantineReactTable<InitiativesResponses.tableSummary>({
    data: actuals,
    columns: columnsActuals,
    enablePagination: false,
    ...getDefaultTableOptions(),
  })
  const endOfYearReferenceLine = dayjs
    .utc(startDateReferenceLine)
    .endOf("year")
    .format("MMM YYYY")
  // const snoozeReferenceline = snoozeDate
  //   ? {
  //       label: "Snooze until",
  //       labelPosition: "right",
  //       x: dayjs(snoozeDate).format("MMM YYYY"),
  //       strokeWidth: 1,
  //       stroke: "var(--mantine-color-primary-6)",
  //     }
  //   : snoozeValue
  //     ? {
  //         label: "Snooze until",
  //         labelPosition: "top",
  //         position: "end",
  //         y: snoozeValue,
  //         strokeWidth: 1,
  //         stroke: "var(--mantine-color-primary-6)",
  //       }
  //     : undefined
  const costGoal = _.find(forecast, (t) => {
    return t.id === "COST_1_MONTH"
  })!.cost
  return (
    <>
      <Group grow align="stretch" h="100%">
        <Paper h="100%">
          <Text fw={700}> Forecast</Text>

          <Group wrap="nowrap" align="stretch">
            <InputWrapper label="Go Live Target" required>
              <MonthPickerInput
                disabled={isReadOnly}
                placeholder="Select Go Live date"
                {...form.getInputProps("startDate")}
              />
            </InputWrapper>
            <InputWrapper label="Expected monthly savings" required>
              <NumberInput
                readOnly={isReadOnly}
                min={0}
                max={100}
                suffix="%"
                allowNegative={false}
                placeholder="Expected savings"
                {...form.getInputProps("expectedSavings")}
              />
            </InputWrapper>
          </Group>
          <Space h={6} />
          <MantineReactTable table={forecastTable} />
        </Paper>
        <Paper h="100%">
          <Text fw={700}> Actuals</Text>
          <Space h={30} />
          <MantineReactTable table={actualsTable} />
        </Paper>
      </Group>
      <Paper component={Stack} align="stretch" gap={16} h="100%">
        <BarChart
          type="stacked"
          h={250}
          data={chartData.map((el) => ({
            ...el,
            agg_date: dayjs(el.agg_date).format("MMM YYYY"),
          }))}
          valueFormatter={(value) =>
            formatNumber(value, "currency", undefined, "USD")
          }
          referenceLines={[
            {
              label: "Initiative Created",
              labelPosition: "bottom",
              position: "start",
              x: createdAtReferenceLine,
              strokeWidth: 2,
              stroke: "var(--mantine-color-dark-5)",
            },
            {
              label: "Go Live Target",
              labelPosition: "top",
              position: "start",
              x: startDateReferenceLine,
              strokeWidth: 2,
              stroke: "var(--mantine-color-dark-5)",
            },
            {
              label: "EOY",
              labelPosition: "top",
              position: "end",
              x: endOfYearReferenceLine,
              strokeWidth: 1,
              stroke: "var(--mantine-color-primary-6)",
            },
            {
              label: "Cost Goal",
              labelPosition: "top",
              position: "end",
              y: costGoal,
              strokeWidth: 2,
              stroke: "var(--mantine-color-indigo-4)",
            },
            //...(snoozeReferenceline ? [snoozeReferenceline] : []),
          ]}
          series={[
            {
              name: "forecastedCost",
              color: "var(--mantine-color-primary-3)",
              label: "Incremental cost forecast",
            },
            {
              name: "forecastedCostAfterSavings",
              color: "var(--mantine-color-primary-2)",
              label: "Forecasted Cost After Savings",
            },
            ...series,
          ]}
          dataKey="agg_date"
        />
      </Paper>
    </>
  )
}

export const SavingsOverview = (props: SavingsOverviewProps) => {
  const savingsOverviewDataQuery = useInitiativeSavingsOverviewData({
    savedViewId: props.savedViewId,
    startDate: dayjs(props.startDate).toDate(),
    createdAt: dayjs(props.createdAt).toDate(),
    expectedSavings: props.expectedSavings,
  })

  return (
    <QueryWrapper query={savingsOverviewDataQuery}>
      {({ data }) => {
        return <_SavingsOverview data={data} {...props} />
      }}
    </QueryWrapper>
  )
}
