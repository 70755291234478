import { Currency } from "@costory/types/prisma-client"

import { FormatedTd } from "@costory/front/pages/Budgets/components/FormatedTd"
import { SectionHead } from "@costory/front/pages/Budgets/components/SectionHead"
import { mediumGrayBorder } from "@costory/front/pages/Budgets/consts"
import { sumOrNull, sumYTDOrNull } from "@costory/front/pages/Budgets/utils"

interface RevisionRowProps {
  allocations: number[]
  name: string
  currency: Currency
}

export const RevisionRow = ({
  currency,
  name,
  allocations,
}: RevisionRowProps) => {
  const valuesYTD = sumYTDOrNull(allocations)
  const valuesTotal = sumOrNull(allocations)

  return (
    <>
      <SectionHead name={name} />
      {allocations.map((value, index) => (
        <FormatedTd
          currency={currency}
          key={index}
          data={value}
          style={{ borderRight: index === 11 ? mediumGrayBorder : "none" }}
        />
      ))}
      <FormatedTd
        currency={currency}
        style={{ borderRight: mediumGrayBorder }}
        data={valuesYTD}
      />
      <FormatedTd
        currency={currency}
        style={{ borderRight: mediumGrayBorder }}
        data={valuesTotal}
      />
    </>
  )
}
