import { BarChart, GridChartBaseProps } from "@mantine/charts"

import { EventsResponses } from "@costory/types/endpoints/events"
import { ExploreResponses } from "@costory/types/endpoints/explorer"
import { Filters } from "@costory/types/filters"

import { ChartTooltip } from "@costory/front/components/charts/ChartTooltip"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { HandlerEvents } from "@costory/front/pages/Explorer/ExplorerMainChart"
import { useExplorerQuery } from "@costory/front/queries/explore"
import { getCharteSerieColor, tickFormatter } from "@costory/front/utils/charts"
import { formatNumber } from "@costory/front/utils/format"

type Props = {
  filters: Filters
  height?: number
  withLegend?: boolean
  drillDownInto?: (value: string) => void
  referenceLines?: GridChartBaseProps["referenceLines"]
  handlerEvents?: HandlerEvents
  events?: EventsResponses.Aggregated[]
}

export const StackedBarChart = ({
  filters,
  height,
  withLegend = true,
  drillDownInto,
  referenceLines,
  handlerEvents,
  events,
}: Props) => {
  const explorerQuery = useExplorerQuery(filters)
  function mergeWithEvents(data: ExploreResponses.ExplorerData) {
    if (events) {
      return data.stackedBarChartData.arr.map((el) => ({
        ...el,
        events: events.filter((ev) => el.agg_date === ev.date)[0]?.events,
      }))
    }
    return data.stackedBarChartData.arr
  }

  return (
    <QueryWrapper query={explorerQuery}>
      {({ data }) => (
        <BarChart
          h={height ?? "100%"}
          pr="50px"
          data={mergeWithEvents(data)}
          dataKey="agg_date"
          type="stacked"
          withLegend={withLegend}
          xAxisProps={{
            angle: 30,
            height: 60,
            textAnchor: "start",
            onClick: (event) => {
              handlerEvents &&
                handlerEvents.createEventFromXAxisClick(
                  event as unknown as { value: string },
                )
            },
            tickFormatter: (val: string) => tickFormatter(val, filters.aggBy),
          }}
          tooltipProps={{
            content: ({ label, payload, coordinate }) => (
              <ChartTooltip
                label={label}
                payload={payload}
                currency={filters.currency}
                coordinate={{
                  x: coordinate?.x ?? 0,
                }}
                aggBy={filters.aggBy}
              />
            ),
          }}
          valueFormatter={(value) =>
            formatNumber(value, "currency", 2, filters.currency)
          }
          series={data.stackedBarChartData.items.map((item, index) => ({
            name: item.name,
            color: getCharteSerieColor(index),
          }))}
          barChartProps={{
            stackOffset: "sign",
            onClick: (val) => {
              handlerEvents &&
                events &&
                handlerEvents.eventClickOnChart(events, val)
            },
          }}
          barProps={{
            // copy paste of Tremor approach to know which bar was clicked...
            onClick: (data) =>
              drillDownInto && drillDownInto(data.tooltipPayload[0].dataKey),
          }}
          referenceLines={referenceLines}
        />
      )}
    </QueryWrapper>
  )
}
