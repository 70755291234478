import {
  ActionIcon,
  Group,
  InputWrapper,
  Loader,
  Select,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core"
import { useField, useForm, zodResolver } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { showNotification } from "@mantine/notifications"
import {
  IconDeviceFloppy,
  IconLock,
  IconLockOpen,
  IconReload,
} from "@tabler/icons-react"
import { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"

import {
  initiativeSchema,
  InitiativesRequests,
  InitiativesResponses,
} from "@costory/types/endpoints/initiatives"
import { InitiativeSnoozeType } from "@costory/types/prisma-client"

import dayjs from "@costory/shared/dayjs"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { InitiativeForm } from "@costory/front/pages/Initiatives/InitiativeForm"
import { useAuthState } from "@costory/front/queries/auth"
import {
  useEditInitiativeMutation,
  useGetInitiativeQuery,
  useInitiativeFormDataQuery,
} from "@costory/front/queries/initiatives"
import { PropsWithData } from "@costory/front/utils/propsWithData"

const _InitiativePage = ({
  data: { latestInitiative, history },
}: PropsWithData<InitiativesResponses.GetInitiative>) => {
  const [selectedInitiative, setSelectedInitiative] = useState(latestInitiative)
  const historyField = useField({
    initialValue: latestInitiative.versionId,
    onValueChange(value) {
      const selected = history.find((i) => i.versionId === value)
      setSelectedInitiative(selected ?? latestInitiative)
    },
  })

  useEffect(() => {
    historyField.setValue(latestInitiative.versionId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestInitiative])

  const historySelectItems = useMemo(
    () => [
      {
        label: `Latest (${dayjs(latestInitiative.createdAt).local().format("ddd, MMM D, YYYY h:mm a")})`,
        value: latestInitiative.versionId,
      },
      ...history.map((olderVersion) => ({
        label: dayjs(olderVersion.createdAt).format("ddd, MMM D, YYYY h:mm a"),
        value: olderVersion.versionId,
      })),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [latestInitiative],
  )

  const isHistory = historyField.getValue() !== latestInitiative.versionId
  return (
    <Stack>
      <EditInitiativeForm
        initiative={selectedInitiative}
        onVersionIdSelect={(versionId) => historyField.setValue(versionId)}
        versionId={historyField.getValue()}
        isHistory={isHistory}
        historySelectItems={historySelectItems}
      />
    </Stack>
  )
}

interface EditInitiativeFormProps {
  initiative: InitiativesResponses.GetInitiative["latestInitiative"]
  isHistory: boolean
  onVersionIdSelect: (versionId: string) => void
  versionId: string
  historySelectItems: { label: string; value: string }[]
}
const EditInitiativeForm = ({
  initiative,
  isHistory,
  onVersionIdSelect,
  versionId,
  historySelectItems,
}: EditInitiativeFormProps) => {
  const { mutateAsync: editInitiative, isPending } = useEditInitiativeMutation()
  const auth = useAuthState()
  const isAllowdToEdit = auth?.user?.id == initiative.createdById
  const initiativeFormDataQuery = useInitiativeFormDataQuery(
    initiative.savedViewId,
  )
  const [isLocked, { open: lock, close: unlock }] = useDisclosure(true)

  const initialValues = {
    ...initiative,
    startDate: dayjs(initiative.startDate).startOf("month").toDate(),
    createdAt: dayjs().toDate(),
    snoozeDate:
      initiative.snoozeType === InitiativeSnoozeType.Date
        ? dayjs(initiative.snoozeDate).toDate()
        : null,
  }

  const form = useForm<InitiativesRequests.CreateInitiative>({
    initialValues,
    validate: zodResolver(initiativeSchema),
  })

  useEffect(() => {
    form.setInitialValues(initialValues)
    form.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiative])

  const isFormTouched = form.isDirty()

  const reset = () => {
    form.reset()
  }

  const handleClickLock = () => {
    lock()
    reset()
  }

  const handleClickSave = async () => {
    try {
      await editInitiative({
        id: initiative.id,
        ...form.values,
      })
      showNotification({
        title: "Initiative updated",
        message: "Initiative has been updated",
        color: "green",
      })
      lock()
    } catch (e) {
      showNotification({
        title: "Error",
        message: e as string,
        color: "red",
      })
    }
  }

  return (
    <QueryWrapper query={initiativeFormDataQuery}>
      {({ data }) => (
        <Stack>
          <Group justify="space-between" align="flex-end">
            <Group>
              <Group align="end">
                <Title>
                  [CSTRY-{initiative.code}] {initiative.name}
                </Title>
                <Text mb={6}>{initiative.description}</Text>
                {isHistory && (
                  <Text mb={6} c="gray.4">
                    This is an older version
                  </Text>
                )}
              </Group>
              {isAllowdToEdit &&
                !isHistory &&
                (isLocked ? (
                  <Tooltip label="Unlock changes">
                    <ActionIcon onClick={unlock}>
                      <IconLock />
                    </ActionIcon>
                  </Tooltip>
                ) : (
                  <Group>
                    <Tooltip label="Toggle edit mode">
                      <ActionIcon onClick={handleClickLock}>
                        <IconLockOpen />
                      </ActionIcon>
                    </Tooltip>
                    {isFormTouched &&
                      (isPending ? (
                        <Loader />
                      ) : (
                        <Group>
                          <Tooltip label="Reset changes">
                            <ActionIcon onClick={reset}>
                              <IconReload />
                            </ActionIcon>
                          </Tooltip>
                          <Tooltip label="Save changes">
                            <ActionIcon onClick={handleClickSave}>
                              <IconDeviceFloppy />
                            </ActionIcon>
                          </Tooltip>
                        </Group>
                      ))}
                  </Group>
                ))}
            </Group>
            <InputWrapper label="Version">
              <Select
                allowDeselect={false}
                data={historySelectItems}
                // Value cannot be null because of allowDeselect={false}
                onChange={(value) => onVersionIdSelect(value!)}
                value={versionId}
              />
            </InputWrapper>
          </Group>
          <InitiativeForm
            existingInitiative={initiative}
            form={form}
            data={data}
            isReadOnly={isLocked || isHistory}
          />
        </Stack>
      )}
    </QueryWrapper>
  )
}

export const InitiativePage = () => {
  const { initiativeId } = useParams<{ initiativeId: string }>()
  const query = useGetInitiativeQuery(initiativeId!)

  return (
    <QueryWrapper query={query}>
      {({ data }) => <_InitiativePage data={data} />}
    </QueryWrapper>
  )
}
