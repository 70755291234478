import { formatQuery, RuleGroupTypeAny } from "react-querybuilder"

import { SearchAcrossValuesSelection } from "@costory/front/components/queryBuilder/types"

export const generateRule = (
  currentSelection: SearchAcrossValuesSelection,
  not: boolean,
): RuleGroupTypeAny | null => {
  if (!currentSelection.length) {
    return null
  }

  // Single column
  if (currentSelection.length === 1 && currentSelection[0].type === "column") {
    const { column } = currentSelection[0]
    return {
      combinator: "and",
      rules: [
        {
          field: column,
          // Checking a column means we want to check for non-nullity.
          operator: not ? "null" : "notNull",
          value: "",
          valueSource: "value",
        },
      ],
    }
  }

  // Multiple columns - error case
  if (
    currentSelection.length > 1 &&
    currentSelection.some((item) => item.type === "column")
  ) {
    throw new Error("Multiple columns selected - not supported")
  }

  // Single value or multiple values for the same column
  const valueItems = currentSelection.filter(({ type }) => type === "value")
  const valueArray = valueItems.map(({ value }) => value)
  return {
    combinator: "and",
    not,
    rules: [
      {
        field: valueItems[0].column,
        operator: "in",
        valueSource: "value",
        value: valueArray,
      },
    ],
  } as RuleGroupTypeAny
}

export const generateCELRule = (
  currentSelection: SearchAcrossValuesSelection,
  not: boolean,
) => {
  const rule = generateRule(currentSelection, not)
  if (!rule) {
    return ""
  }

  // Wrap the rule in a group to add parentheses to the exported CEL string
  const group = {
    combinator: "and",
    not: false,
    rules: [rule],
  } as RuleGroupTypeAny

  return formatQuery(group, "cel")
}
