import { Group, Modal, Pill } from "@mantine/core"
import dayjs from "dayjs"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { useMemo } from "react"

import { EventsResponses } from "@costory/types/endpoints/events"

import { DEFAULT_DATE_FORMAT } from "@costory/shared/const"

import { getDefaultTableOptions } from "@costory/front/utils/table"

export function ModalZoomOnEvents({
  events,
  isOpened,
  onClose,
}: {
  events: EventsResponses.Aggregated
  isOpened: boolean
  onClose: () => void
}) {
  const columns = useMemo(() => {
    const columnHelper =
      createMRTColumnHelper<EventsResponses.Aggregated["events"][0]>()
    return [
      columnHelper.accessor("name", {
        header: "name",
        filterVariant: "select",
      }),
      columnHelper.accessor("description", {
        header: "Description",
      }),
      columnHelper.accessor("tags", {
        header: "Tags",
        Cell: ({ cell }) => (
          <Group>
            {cell
              .getValue()
              .map((el) => `${el.key}:${el.value}`)
              .map((el, index) => (
                <Pill key={index}> {el}</Pill>
              ))}
          </Group>
        ),
      }),
      columnHelper.accessor("date", {
        header: "Date",
        filterVariant: "select",
        Cell: ({ cell }) => (
          <>{dayjs(cell.getValue()).format(DEFAULT_DATE_FORMAT)}</>
        ),
      }),
    ]
  }, [])

  const table = useMantineReactTable<EventsResponses.Aggregated["events"][0]>({
    columns,
    data: events.events,
    ...getDefaultTableOptions(),
  })
  return (
    <>
      <Modal title="Events" opened={isOpened} onClose={onClose} size="70%">
        <MantineReactTable table={table} />
      </Modal>
    </>
  )
}
