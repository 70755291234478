import { useContext } from "react"

import { BudgetTableContext } from "@costory/front/pages/Budgets/context/BudgetTableContext"

export const useBudgetTableContext = () => {
  const context = useContext(BudgetTableContext)

  if (!context) {
    throw new Error("useFormData must be used within a BudgetGridContext")
  }

  return context
}
