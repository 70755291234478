import { AxiosResponse } from "axios"

import {
  BudgetsRequests,
  BudgetsResponses,
} from "@costory/types/endpoints/budgets"

import { apiClient } from "@costory/front/lib/apiClient"

export const getBudgetsList = async () => {
  const response =
    await apiClient.get<BudgetsResponses.GetAllBudgets>("/budgets")
  return response.data
}

export const getBudget = async (id: string) => {
  const response = await apiClient.get<BudgetsResponses.GetBudget>(
    "/budgets/" + id,
  )
  return response.data
}

export const deleteBudget = async (id: string) =>
  await apiClient.delete(`/budgets/${id}`)

export interface DuplicateBudgetParams {
  id: string
  ownerId: string
}
export const duplicateBudget = async ({
  id,
  ownerId,
}: DuplicateBudgetParams) => {
  const response = await apiClient.post<BudgetsResponses.DuplicateBudget>(
    `/budgets/${id}/duplicate`,
    { ownerId },
  )
  return response.data
}

export const getBudgetCreationFormData = async () => {
  const response =
    await apiClient.get<BudgetsResponses.BudgetFormData>("/budgets/new")
  return response.data
}

export const createBudget = async (data: BudgetsRequests.CreateBudget) => {
  const response = await apiClient.post<
    BudgetsResponses.CreateBudget,
    AxiosResponse<BudgetsResponses.CreateBudget>,
    BudgetsRequests.CreateBudget
  >("/budgets", data)
  return response.data
}

type UpdateBudgetParams = BudgetsRequests.UpdateBudget & { id: string }

export const updateBudget = async ({ id, ...data }: UpdateBudgetParams) => {
  const response = await apiClient.put<
    BudgetsResponses.UpdateBudget,
    AxiosResponse<BudgetsResponses.UpdateBudget>,
    BudgetsRequests.UpdateBudget
  >(`/budgets/${id}`, data)
  return response.data
}

export const getCSVExport = async (budgetId: string) => {
  const response = await apiClient.get<BudgetsResponses.ExportToCSV>(
    `/budgets/${budgetId}/csv`,
  )
  const blob = new Blob([response.data], { type: "text/csv" })

  return blob
}
