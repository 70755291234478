import { showNotification } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"

import {
  VirtualDimensionsRequests,
  VirtualDimensionsResponses,
} from "@costory/types/endpoints/virtualDimensions"

import { apiClient } from "@costory/front/lib/apiClient"
import { queryClient } from "@costory/front/lib/queryClient"

export const useListVirtualDimensionsQuery = () => {
  return useQuery({
    queryKey: ["virtual-dimensions"],
    queryFn: async () => {
      const response = await apiClient.get<
        VirtualDimensionsResponses.VirtualDimension[]
      >("/virtual-dimensions")
      return response.data
    },
  })
}

export const useVirtualDimensionQuery = (id: string) => {
  return useQuery({
    queryKey: ["virtual-dimensions", id],
    queryFn: async () => {
      const response =
        await apiClient.get<VirtualDimensionsResponses.VirtualDimension>(
          `/virtual-dimensions/${id}`,
        )
      return response.data
    },
  })
}
export const useVirtualDimensionDraftQuery = (id: string, isEnabled = true) => {
  return useQuery({
    queryKey: ["virtual-dimensions", "draft", id],
    enabled: isEnabled,
    queryFn: async () => {
      const response =
        await apiClient.get<VirtualDimensionsResponses.VirtualDimensionVersion>(
          `/virtual-dimensions/draft/${id}`,
        )
      const out = JSON.parse(
        response.data.data as string,
      ) as VirtualDimensionsRequests.EditVirtualDimension
      return { ...out, id: response.data.virtualDimensionId }
    },
  })
}
export const useCreateVirtualDimensionMutation = () => {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async (
      data: VirtualDimensionsRequests.NewDraftVirtualDimensions,
    ) => {
      const response = await apiClient.post("/virtual-dimensions", data)
      return response.data
    },
    onSuccess: async () => {
      // TODO: better messages
      showNotification({ color: "green", title: "Success", message: "Saved" })
      await queryClient.invalidateQueries({ queryKey: ["virtual-dimensions"] })
      navigate("/virtual-dimensions")
    },
    onError: (error: { error: string }) => {
      showNotification({ color: "red", title: "Error", message: error.error })
    },
  })
}

export const useUpdateVirtualDimensionMutation = (id: string) => {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async (
      data: VirtualDimensionsRequests.NewDraftVirtualDimensions,
    ) => {
      const response = await apiClient.put(`/virtual-dimensions/${id}`, data)
      return response.data
    },
    onSuccess: async () => {
      // TODO: better messages
      showNotification({ color: "green", title: "Success", message: "Saved" })
      await queryClient.invalidateQueries({ queryKey: ["virtual-dimensions"] })
      navigate("/virtual-dimensions")
    },
    onError: (error: { error: string }) => {
      showNotification({ color: "red", title: "Error", message: error.error })
    },
  })
}

export const useRefreshEstimation = (
  setResult: (
    data: VirtualDimensionsResponses.RefreshEstimationDataResult,
  ) => void,
) => {
  return useMutation({
    mutationFn: async (data: VirtualDimensionsRequests.RefreshEstimation) => {
      const response = await apiClient.post(
        "/virtual-dimensions/refresh-result",
        data,
      )
      return response.data
    },
    onSuccess: async (
      data: VirtualDimensionsResponses.RefreshEstimationDataResult,
    ) => {
      setResult(data)
      showNotification({
        color: "green",
        title: "Success",
        message: "Result computed",
      })
    },
    onError: (error) => {
      showNotification({ color: "red", title: "Error", message: error.message })
    },
  })
}

export const useCreateDraftVirtualDimensionMutation = () => {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async (
      data: VirtualDimensionsRequests.NewDraftVirtualDimensions,
    ) => {
      const response = await apiClient.post("/virtual-dimensions/draft/", data)
      return response.data
    },
    onSuccess: (data: VirtualDimensionsResponses.VirtualDimensionDraft) => {
      showNotification({
        color: "green",
        title: "Success",
        message: "Saved a new draft",
      })
      queryClient.invalidateQueries({ queryKey: ["virtual-dimensions"] })
      navigate(
        `/virtual-dimensions/${data.parentVirtualDimId}/draft/${data.draftId}`,
      )
    },
    onError: (error: { error: string }) => {
      showNotification({ color: "red", title: "Error", message: error.error })
    },
  })
}

export const useDuplicateVirtualDimensionMutation = () => {
  return useMutation({
    mutationFn: async (data: VirtualDimensionsRequests.DuplicateVirtualDim) => {
      const response = await apiClient.put(
        `/virtual-dimensions/duplicate/${data.id}`,
      )
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["virtual-dimensions"] })
      showNotification({
        color: "green",
        title: "Success",
        message: "Duplicated the virtual dim",
      })
    },
    onError: (error: { error: string }) => {
      showNotification({ color: "red", title: "Error", message: error.error })
    },
  })
}

export const useDeleteVirtualDim = () => {
  return useMutation({
    mutationFn: async (id: string) => {
      const response = await apiClient.delete(`/virtual-dimensions/${id}`)
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["virtual-dimensions"] })
      showNotification({
        color: "green",
        title: "Success",
        message: "Successfully archived",
      })
    },
    onError: (error: { error: string }) => {
      showNotification({ color: "red", title: "Error", message: error.error })
    },
  })
}

interface VirtualDimensionCostQuery {
  id: string
  startDate: Date
  businessMetricId: string
}
export const useVirtualDimensionCostQuery = ({
  id,
  startDate,
  businessMetricId,
}: VirtualDimensionCostQuery) => {
  return useQuery({
    queryKey: ["virtual-dimensions", id, "cost", startDate, businessMetricId],
    queryFn: async () => {
      const response =
        await apiClient.post<VirtualDimensionsResponses.GetVirtualDimensionCost>(
          `/virtual-dimensions/${id}/cost`,
          { startDate, businessMetricId },
        )
      return response.data
    },
  })
}
