import { Button, Group, Stack } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { showNotification } from "@mantine/notifications"
import { useNavigate } from "react-router-dom"

import {
  InitiativesRequests,
  initiativeSchema,
} from "@costory/types/endpoints/initiatives"
import {
  InitiativeEffort,
  InitiativeSnoozeType,
  InitiativeStatus,
} from "@costory/types/prisma-client"

import dayjs from "@costory/shared/dayjs"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { InitiativeForm } from "@costory/front/pages/Initiatives/InitiativeForm"
import {
  useCreateInitiativeMutation,
  useInitiativeFormDataQuery,
} from "@costory/front/queries/initiatives"

interface CreateInitiativeFormProps {
  savedViewId: string
}

export const CreateInitiativeForm = ({
  savedViewId,
}: CreateInitiativeFormProps) => {
  const initiativeFormDataQuery = useInitiativeFormDataQuery(savedViewId)
  const navigate = useNavigate()
  const { mutateAsync: createInitiative, isPending } =
    useCreateInitiativeMutation()

  const initiativeForm = useForm<InitiativesRequests.CreateInitiative>({
    initialValues: {
      name: "",
      ownerId: "",
      description: "",
      effort: InitiativeEffort.Medium,
      ticketUrl: "",
      status: InitiativeStatus.InProgress,
      startDate: dayjs().startOf("month").add(1, "month").toDate(),
      createdAt: dayjs().toDate(),
      expectedSavings: 10,
      savedViewId,
      snoozeType: InitiativeSnoozeType.NoSnooze,
    },
    validate: zodResolver(initiativeSchema),
  })

  const handleSubmit = async (values: typeof initiativeForm.values) => {
    try {
      const createdInitiative = await createInitiative(values)
      showNotification({
        title: "Initiative created",
        message: "Initiative has been created",
        color: "green",
      })
      navigate(`/initiatives/${createdInitiative.id}`)
    } catch (e) {
      showNotification({
        title: "Error",
        message: e as string,
        color: "red",
      })
    }
  }

  const onClickSubmit = () => initiativeForm.onSubmit(handleSubmit)()

  return (
    <QueryWrapper query={initiativeFormDataQuery}>
      {({ data }) => (
        <Stack gap={8}>
          <InitiativeForm form={initiativeForm} data={data} />
          <Group justify="flex-end">
            <Button onClick={onClickSubmit} loading={isPending}>
              Create
            </Button>
          </Group>
        </Stack>
      )}
    </QueryWrapper>
  )
}
