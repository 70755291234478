import { parseCEL } from "react-querybuilder/parseCEL"

import { celParser } from "@costory/front/components/queryBuilder/celParser"

export const canParse = (query: string) => {
  if (query.trim().length === 0) {
    return true
  }

  try {
    celParser.parse(query)
    parseCEL(query)
    return true
  } catch (e) {
    console.log(`Could not parse CEL query: ${query}`, e)
    return false
  }
}
