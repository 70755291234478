import _, { isNumber } from "lodash"

import dayjs from "@costory/shared/dayjs"

export const sumOrNull = (values: (number | null | undefined)[]) =>
  values.every((el) => !isNumber(el)) ? null : _.sum(values)
export const sumYTDOrNull = (values: (number | null | undefined)[]) =>
  values.every((el) => !isNumber(el))
    ? null
    : _.sum(values.map((el) => (!el ? 0 : el)).slice(0, dayjs().month() + 1))
