import { MantineProvider } from "@mantine/core"
import { DatesProvider } from "@mantine/dates"
import { ModalsProvider } from "@mantine/modals"
import { Notifications } from "@mantine/notifications"
import * as Sentry from "@sentry/react"
import { extraErrorDataIntegration } from "@sentry/react"
import ReactDOM from "react-dom/client"

import { theme } from "@costory/front/assets/theme/index"

import App from "./App"

const gtmURL = import.meta.env.VITE_GTM_URL

// Function to inject the custom GTM script into the <head>
const injectCustomGTM = () => {
  if (!document.querySelector(`script[src*="${gtmURL}"]`)) {
    const script = document.createElement("script")
    script.async = true
    script.textContent = `
      (function(w,d,s,l,i){
        w[l]=w[l]||[];
        w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s);
        j.async=true;
        j.src="https://gg.costory.io/1fxugxtjpi.js?"+i;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','c2pi8=${import.meta.env.VITE_GTM_STAPE_ID}&page=3');
    `
    document.head.appendChild(script)
  }
}

// Function to append the noscript GTM snippet into the <body>
const appendNoscriptGTM = () => {
  if (!document.querySelector(`iframe[src*="${gtmURL}/ns.html"]`)) {
    const noscript = document.createElement("noscript")
    const gtmId = import.meta.env.VITE_GTM_ID // Extract GTM ID from environment variables
    noscript.innerHTML = `
      <iframe src="${gtmURL}/ns.html?id=${gtmId}"
              height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `
    document.body.appendChild(noscript)
  }
}

// Inject the custom GTM script and noscript snippet

// const tagManagerArgs = {
//   gtmId: import.meta.env.VITE_GTM_ID,
//   source: import.meta.env.VITE_GTM_URL,
//   events: [
//     {
//       event: "consent_loaded",
//       consentAnalytics: true,
//       consentAds: false,
//       consentPreferences: true,
//     },
//   ],
// }

// Move below after tests
// TagManager.initialize(tagManagerArgs)

if (import.meta.env.VITE_ENVIRONMENT !== "development") {
  injectCustomGTM()
  appendNoscriptGTM()
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT,
    integrations: [
      extraErrorDataIntegration(),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    tracePropagationTargets: ["localhost"],
    replaysSessionSampleRate: 0.1, // 10% sample rate
    replaysOnErrorSampleRate: 1.0, // 100% sample rate for errors
  })
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <MantineProvider theme={theme}>
    <Notifications />
    <DatesProvider settings={{ locale: "en" }}>
      <ModalsProvider>
        <App />
      </ModalsProvider>
    </DatesProvider>
  </MantineProvider>,
)
