import {
  EventsRequests,
  EventsResponses,
} from "@costory/types/endpoints/events"

import { EventsEditModal } from "@costory/front/pages/Events/EventsForm"
import { useEditEventMutation } from "@costory/front/queries/events"

export const EditEventForm = ({
  data,
  isOpen,
  onClose,
}: {
  data: EventsResponses.List
  isOpen: boolean
  onClose: () => void
}) => {
  const { mutateAsync: editEvent } = useEditEventMutation(
    data?.id || "",
    onClose,
  )

  const onSave = async (event: EventsRequests.Event) => {
    await editEvent(event)
  }

  return (
    <EventsEditModal
      data={data}
      onSave={onSave}
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}
