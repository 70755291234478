import {
  Accordion,
  Button,
  Group,
  Pill,
  Select,
  Stack,
  Text,
} from "@mantine/core"
import { IconBrandGithub, IconBrandSlack } from "@tabler/icons-react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useContext, useEffect } from "react"
import { Link, useSearchParams } from "react-router-dom"

import { IntegrationResponses } from "@costory/types/endpoints/integration"

import {
  UserContext,
  useUser,
} from "@costory/front/components/auth/UserContext"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { apiClient } from "@costory/front/lib/apiClient"
import { IntegrationAccordionItem } from "@costory/front/pages/Integration/IntegrationAccordionItem"
import { useSlackChannelsQuery } from "@costory/front/queries/integrations"

const SLACK_INSTALLATION_URL = `${import.meta.env.VITE_API_URL}/slack/install`

const SlackIntegration = () => {
  const user = useUser()
  const query = useSlackChannelsQuery()
  return (
    <IntegrationAccordionItem
      name="Slack"
      icon={IconBrandSlack}
      isConnected={!!user.currentOrg.slackInstallation}
    >
      <Stack>
        <Group>
          Install App
          <Link to={SLACK_INSTALLATION_URL} target="_blank">
            <Button color="gray" leftSection={<IconBrandSlack />}>
              {user.currentOrg.slackInstallation ? "Update" : "Install"} Costory
              Slack App
            </Button>
          </Link>
        </Group>

        {!!user.currentOrg.slackInstallation && (
          <QueryWrapper query={query}>
            {({ data }) => (
              <>
                <Group>
                  Channels Accessible
                  {data.map((el, index) => (
                    <Pill key={index}>{el.name} </Pill>
                  ))}
                </Group>
              </>
            )}
          </QueryWrapper>
        )}
      </Stack>
    </IntegrationAccordionItem>
  )
}
const SlackIntegrationDeprecated = ({
  data,
}: {
  data: IntegrationResponses.SlackCheck
}) => {
  return (
    <IntegrationAccordionItem
      name="Slack Deprecated"
      icon={IconBrandSlack}
      isConnected={!!data.channels}
    >
      <Stack>
        {data.channels && (
          <Group gap={4}>
            List Available Channels
            <Select placeholder="Channels" data={data.channels} searchable />
          </Group>
        )}
      </Stack>
    </IntegrationAccordionItem>
  )
}

const GITHUB_INSTALLATION_URL =
  process.env.NODE_ENV === "development"
    ? "https://github.com/apps/dev-costory-agent/installations/new"
    : "https://github.com/apps/costory-agent/installations/new"

const GitHubIntegration = () => {
  const { user, updateUser } = useContext(UserContext)
  const [searchParams] = useSearchParams()

  const { mutate: saveGitHubAppIdIfExists, isPending } = useMutation({
    mutationFn: async () => {
      const githubAppId = searchParams.get("installation_id")
      if (githubAppId && user.currentOrg.githubAppId !== githubAppId) {
        await apiClient.post("/github/installation", { githubAppId })
        updateUser((prev) => ({
          ...prev,
          currentOrg: { ...prev.currentOrg, githubAppId },
        }))
      }
    },
  })

  useEffect(() => {
    saveGitHubAppIdIfExists()
  }, [saveGitHubAppIdIfExists])

  const isConnected = !!user.currentOrg.githubAppId

  return (
    <IntegrationAccordionItem
      name="GitHub"
      icon={IconBrandGithub}
      isConnected={isConnected}
      isLoading={isPending}
    >
      <Link to={GITHUB_INSTALLATION_URL}>
        <Button color="black" leftSection={<IconBrandGithub />}>
          {isConnected
            ? "Update Costory Agent permissions"
            : "Install Costory Agent on GitHub"}
        </Button>
      </Link>
    </IntegrationAccordionItem>
  )
}

export const IntegrationPage = () => {
  const slackQuery = useQuery({
    queryKey: ["slack_check"],
    queryFn: async () => {
      const response =
        await apiClient.get<IntegrationResponses.SlackCheck>("/slack/check")
      return response.data
    },
  })

  return (
    <Stack>
      <Stack gap={2}>
        <Text>Connect Costory to your tools</Text>
      </Stack>
      <Accordion chevronPosition="left">
        <QueryWrapper query={slackQuery}>
          {({ data }) => <SlackIntegrationDeprecated data={data} />}
        </QueryWrapper>
        <SlackIntegration />
        <GitHubIntegration />
      </Accordion>
    </Stack>
  )
}
