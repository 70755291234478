import { ActionIcon, Button, Tooltip } from "@mantine/core"
import { showNotification } from "@mantine/notifications"
import { IconDownload } from "@tabler/icons-react"

import { useVirtualDimensionDraftQuery } from "@costory/front/queries/virtualDimensions"
import { downloadBlob } from "@costory/front/utils/downloadBlob"

interface VirtualDimensionDraftCSVExportButtonProps {
  virtualDimensionDraftId: string
  variant?: "compact" | "default"
}

export const VirtualDimensionDraftCSVExportButton = ({
  virtualDimensionDraftId,
  variant = "default",
}: VirtualDimensionDraftCSVExportButtonProps) => {
  const getVirtualDimensionDraftQuery = useVirtualDimensionDraftQuery(
    virtualDimensionDraftId,
    false,
  )
  const handleDownloadCSVExport = async () => {
    const csvExport = await getVirtualDimensionDraftQuery.refetch()

    if (csvExport.status !== "success") {
      showNotification({
        title: "Error",
        message: "Failed to download JSON export",
        color: "red",
      })
      return
    }
    const blob = new Blob([JSON.stringify(csvExport.data)], {
      type: "text/json",
    })
    downloadBlob(blob, `vdim_${csvExport.data.name}.json`)
  }

  return (
    <Tooltip label="Download JSON export">
      {variant === "compact" ? (
        <ActionIcon
          onClick={handleDownloadCSVExport}
          size="sm"
          loading={getVirtualDimensionDraftQuery.isLoading}
        >
          <IconDownload size="inherit" />
        </ActionIcon>
      ) : (
        <Button
          variant="subtle"
          onClick={handleDownloadCSVExport}
          leftSection={<IconDownload />}
          loading={getVirtualDimensionDraftQuery.isLoading}
        >
          Export to JSON
        </Button>
      )}
    </Tooltip>
  )
}
