import { Affix, Button, Group, rem, Tree, useTree } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useClipboard, useDisclosure } from "@mantine/hooks"
import { IconDeviceFloppy, IconFileExport } from "@tabler/icons-react"
import _ from "lodash"
import { useMemo, useState } from "react"

import { AxesResponses } from "@costory/types/endpoints/axes"
import {
  DigestResponses,
  digestSchema,
  DigestType,
} from "@costory/types/endpoints/digest"
import { GraphResponses } from "@costory/types/endpoints/graph"
import { Filters } from "@costory/types/filters"
import { DatePresetDigest } from "@costory/types/prisma-client"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { apiClient } from "@costory/front/lib/apiClient"
import { ButtonAddTo } from "@costory/front/pages/DigestV2/ButtonAddToDigest"
import { DigestTitle } from "@costory/front/pages/DigestV2/DigestTitle"
import { ExplorerDrawer } from "@costory/front/pages/DigestV2/Drawer"
import { NodeRendering } from "@costory/front/pages/DigestV2/NodeRendering"
import {
  addChild,
  convertToTree,
  formatMarkdown,
  getDefaultRootElem,
  NodeTree,
  replaceDigestItem,
} from "@costory/front/pages/DigestV2/helper"
import { useAxesQuery } from "@costory/front/queries/axes"
import { useQueryDigestData } from "@costory/front/queries/digest"

type DigestFormProps = {
  initialValues:
    | DigestResponses.Digest
    | { datePreset: DatePresetDigest; period: string }
  onSave: (digest: DigestType) => void
}
const _DigestForm = (
  props: DigestFormProps & {
    axesData: AxesResponses.Axis[]
    digest: DigestResponses.Investigation
  },
) => {
  const getDigestData = useMemo(() => {
    const mapper = new Map<string, DigestResponses.Investigation[0]>()
    props.digest.forEach((elem) => {
      mapper.set(JSON.stringify(_.orderBy(elem.arr, (d) => d.key)), elem)
    })
    return mapper
  }, [props.digest])
  const defaultItems = [getDefaultRootElem()]
  const [data, setData] = useState<NodeTree[]>(
    convertToTree(
      "items" in props.initialValues ? props.initialValues.items : defaultItems,
      getDigestData,
    ),
  )
  const form = useForm<DigestType>({
    initialValues: {
      items: defaultItems,
      status: "DRAFT",
      name: "New Cost Digest",
      ...props.initialValues,
    },
    validate: zodResolver(digestSchema),
    onValuesChange: (val) => {
      setData(convertToTree(val.items, getDigestData))
    },
  })
  const [selectedNodeDrawer, setSelectedNodeDrawer] = useState<NodeTree>()
  const [openedDrawerExplorer, handlersDrawerExplorer] = useDisclosure()
  const exploreInDrawer = (node: NodeTree) => {
    setSelectedNodeDrawer(node)
    handlersDrawerExplorer.open()
  }
  const clipboard = useClipboard({ timeout: 500 })
  const tree = useTree({
    initialExpandedState:
      "items" in props.initialValues
        ? { [props.initialValues.items[0].id]: true }
        : undefined,
  })
  const setImgUrl = async (filters: Filters, nodeId: string) => {
    handlersDrawerExplorer.close()
    apiClient.post<GraphResponses.Url>("/graph/url", filters).then((data) => {
      replaceDigestItem(
        form,
        { imgUrl: data.data.url, filtersUrl: filters },
        nodeId,
      )
    })
  }
  const firstNode = (direction: string) => {
    const item = getDefaultRootElem()
    form.insertListItem("items", item)
    addChild(form, direction, props.digest, item.id, () => 1)
  }
  return (
    <>
      <Affix position={{ bottom: 50, right: 50 }}>
        <Group>
          <Button
            color={clipboard.copied ? "teal" : undefined}
            onClick={() =>
              clipboard.copy(
                formatMarkdown(
                  form,
                  props.axesData,
                  getDigestData,
                  "2024-12-01",
                ),
              )
            }
            leftSection={
              <IconFileExport style={{ width: rem(16), height: rem(16) }} />
            }
          >
            Export to Mrkdown (clipboard)
          </Button>
          <Button
            onClick={() => props.onSave(form.getValues())}
            leftSection={
              <IconDeviceFloppy style={{ width: rem(16), height: rem(16) }} />
            }
          >
            Save Digest
          </Button>
        </Group>
      </Affix>
      <DigestTitle form={form} digestData={props.digest} />
      {selectedNodeDrawer && openedDrawerExplorer && (
        <ExplorerDrawer
          setImgPromise={
            selectedNodeDrawer.isOthers
              ? undefined
              : (filters) => setImgUrl(filters, selectedNodeDrawer.nodeProps.id)
          }
          negationValues={selectedNodeDrawer.othersValues || []}
          opened={openedDrawerExplorer}
          close={handlersDrawerExplorer.close}
          position={selectedNodeDrawer.nodeProps.position}
          period={
            props.initialValues.datePreset === "MTD" ? "MTD" : "MonthToMonth"
          }
        />
      )}
      <ButtonAddTo addChild={firstNode} digest={props.digest} />
      <Tree
        tree={tree}
        data={data}
        renderNode={({ node, expanded, hasChildren, elementProps, tree }) => {
          return (
            <NodeRendering
              exploreInDrawer={() => exploreInDrawer(node as NodeTree)}
              node={node as NodeTree}
              hasChildren={hasChildren}
              elementProps={elementProps}
              expanded={expanded}
              tree={tree}
              form={form}
              digest={props.digest}
            />
          )
        }}
      />
    </>
  )
}

export const DigestForm = (props: DigestFormProps) => {
  const query = useQueryDigestData(
    (props.initialValues.datePreset || "MTD") as DatePresetDigest,
    props.initialValues.period,
  )
  const axes = useAxesQuery()
  return (
    <>
      <QueryWrapper query={axes}>
        {(axesData) => (
          <>
            <QueryWrapper query={query}>
              {(digest) => (
                <_DigestForm
                  {...props}
                  digest={digest.data}
                  axesData={axesData.data}
                />
              )}
            </QueryWrapper>
          </>
        )}
      </QueryWrapper>
    </>
  )
}
