import { showNotification } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"

import { DigestResponses, DigestType } from "@costory/types/endpoints/digest"
import { DatePresetDigest } from "@costory/types/prisma-client"

import { apiClient } from "@costory/front/lib/apiClient"
import { queryClient } from "@costory/front/lib/queryClient"

export const useDigestListQuery = () => {
  return useQuery({
    queryKey: ["digests"],
    queryFn: async () => {
      const response =
        await apiClient.get<DigestResponses.ListDigests[]>("/digest/list")
      return response.data
    },
  })
}

export const useAvailableDataDigestQuery = () => {
  return useQuery({
    queryKey: ["digests", "data"],
    queryFn: async () => {
      const response = await apiClient.get<DigestResponses.AvailableDigests>(
        "/digest/available-data",
      )
      return response.data
    },
  })
}

export const useDeleteDigestMutation = () =>
  useMutation({
    mutationFn: async (id: string) => {
      const response = await apiClient.delete<DigestResponses.Digest>(
        `/digest/edit/${id}`,
      )
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["digests"] })
      showNotification({
        position: "top-right",
        color: "green",
        title: "Digest Archived !",
        message: "Digest Archived successfully",
      })
    },
    onError: (error) => {
      showNotification({
        color: "red",
        position: "top-right",
        title: "Error archiving digest",
        message: error.message,
      })
    },
  })

export const useQueryDigestData = (
  preset: DatePresetDigest,
  period: string,
) => {
  return useQuery({
    queryKey: ["digest", period, preset],
    queryFn: async () => {
      const response = await apiClient.get<DigestResponses.Investigation>(
        `digest/data/${period}/${preset}`,
      )

      return response.data
    },
  })
}

export const useNewDigestMutation = () => {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async (data: DigestType) => {
      const response = await apiClient.post<DigestResponses.Digest>(
        "/digest/new",
        data,
      )
      return response.data
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["digests"] })
      navigate(`/digests/edit/${data.id}`)
      showNotification({
        color: "green",
        position: "top-right",
        title: "Digest Created !",
        message: "Digest Created",
      })
    },
    onError: (error) => {
      showNotification({
        color: "red",
        position: "top-right",
        title: "Error creating digest",
        message: error.message,
      })
    },
  })
}

export const useQueryOpenDigest = (id: string) => {
  return useQuery({
    queryKey: ["digest", id],
    queryFn: async () => {
      const response = await apiClient.get<DigestResponses.Digest>(
        `/digest/edit/${id}`,
      )

      return response.data
    },
  })
}

export const useEditDigestMutation = (id: string) =>
  useMutation({
    mutationFn: async (data: DigestType) => {
      const response = await apiClient.post<DigestResponses.Digest>(
        `/digest/edit/${id}`,
        data,
      )
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["digests"] })
      showNotification({
        color: "green",
        title: "Digest Saved !",
        position: "top-right",
        message: "Digest Saved",
      })
    },
    onError: (error) => {
      showNotification({
        color: "red",
        title: "Error saving digest",
        position: "top-right",
        message: error.message,
      })
    },
  })
