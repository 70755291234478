import { mediumGrayBorder } from "@costory/front/pages/Budgets/consts"

export const Th = (props: React.HTMLProps<HTMLTableCellElement>) => (
  <th
    {...props}
    style={{
      borderRight: mediumGrayBorder,
      borderBottom: mediumGrayBorder,
      backgroundColor: "#f0f0f0",
      textAlign: "left",
      padding: 4,
      height: 40,
      width: 100,
      wordWrap: "break-word",
      maxWidth: 150,
      ...props.style,
    }}
  >
    {props.children}
  </th>
)
