import { Text } from "@mantine/core"

export function UserCell({
  user,
}: {
  user: { firstName: string; lastName: string }
}) {
  return (
    <>
      <Text>
        {user.firstName} {user.lastName}
      </Text>
    </>
  )
}
