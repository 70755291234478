import { useQuery } from "@tanstack/react-query"

import { IntegrationResponses } from "@costory/types/endpoints/integration"

import { apiClient } from "@costory/front/lib/apiClient"

export const useSlackChannelsQuery = () => {
  return useQuery({
    queryKey: ["slack", "channels"],
    queryFn: async () => {
      const response = await apiClient.get<IntegrationResponses.Channels>(
        "/integrations/slack/accessible-channels",
      )
      return response.data
    },
  })
}
