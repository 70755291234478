import { Button, Group, Modal, Tooltip } from "@mantine/core"
import { IconFilePlus } from "@tabler/icons-react"
import {
  MRT_ColumnDef,
  MantineReactTable,
  createMRTColumnHelper,
  useMantineReactTable,
} from "mantine-react-table"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"

import { DigestResponses } from "@costory/types/endpoints/digest"

import dayjs from "@costory/shared/dayjs"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { DateCell } from "@costory/front/components/table/DateCell"
import { useAvailableDataDigestQuery } from "@costory/front/queries/digest"

function _CreateDigest({
  availableData,
}: {
  availableData: DigestResponses.AvailableDigests
}) {
  const navigate = useNavigate()
  const columns = useMemo<
    MRT_ColumnDef<DigestResponses.AvailableDigests[0]>[]
  >(() => {
    const columnHelper =
      createMRTColumnHelper<DigestResponses.AvailableDigests[0]>()
    return [
      columnHelper.accessor("startPeriod", {
        header: "Period",
        Cell: ({ cell }) => <DateCell date={dayjs(cell.getValue()).toDate()} />,
      }),
      columnHelper.accessor("datePreset", {
        header: "Date Preset",
      }),

      columnHelper.display({
        header: "Actions",
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Group gap={8} wrap="nowrap">
            <Tooltip label="Create New Digest">
              <Button
                size="xs"
                variant="outline"
                leftSection={<IconFilePlus size={16} />}
                onClick={() => {
                  navigate(
                    `new/${row.original.startPeriod}/${row.original.datePreset}`,
                  )
                }}
              >
                Create
              </Button>
            </Tooltip>
          </Group>
        ),
      }),
    ]
  }, [navigate])

  const table = useMantineReactTable<DigestResponses.AvailableDigests[0]>({
    columns,
    data: availableData,
  })
  return (
    <>
      <MantineReactTable table={table} />
    </>
  )
}

export function CreateDigestModal({
  opened,
  close,
}: {
  opened: boolean
  close: () => void
}) {
  const query = useAvailableDataDigestQuery()
  return (
    <>
      <Modal title="New Digest" size="xl" opened={opened} onClose={close}>
        <QueryWrapper query={query} allowEmptyArray>
          {({ data }) => <_CreateDigest availableData={data} />}
        </QueryWrapper>
      </Modal>
    </>
  )
}
