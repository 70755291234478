import { ActionIcon, Flex, Loader, TextInput } from "@mantine/core"
import { IconXboxXFilled } from "@tabler/icons-react"
import { useMemo, useRef, useState } from "react"

import { AxesResponses } from "@costory/types/endpoints/axes"

import { generateCELRule } from "@costory/front/components/queryBuilder/CEL/generateRule"
import { apiClient } from "@costory/front/lib/apiClient"

import type {
  SearchAcrossColumnsAndValuesResult,
  SearchAcrossValuesSelection,
} from "../types"

import { ColumValueTree } from "./ColumValueTree"
import { Footer } from "./Footer"

function getAsyncData(searchQuery: string, signal: AbortSignal) {
  return new Promise<SearchAcrossColumnsAndValuesResult>((resolve, reject) => {
    signal.addEventListener("abort", () => {
      reject(new Error("Request aborted"))
    })

    apiClient
      .get<AxesResponses.Axis[]>(`/axes/search/${searchQuery}`)
      .then((response) => {
        const result = response.data.map(({ label, name, values }) => ({
          field: name,
          label,
          values: values.map(({ label }) => label),
        }))

        resolve(result)
      })
  })
}

interface SearchAcrossColumnsAndValuesProps {
  initialFields: AxesResponses.Axis[]
  onInsert: (celString: string) => void
}
const SearchAcrossColumnsAndValues = ({
  initialFields: rawInitialFields,
  onInsert,
}: SearchAcrossColumnsAndValuesProps) => {
  const initialFields = useMemo(
    () =>
      rawInitialFields.map((field) => ({
        field: field.name,
        label: field.label,
        values: field.values.map((value) => value.label),
      })),
    [rawInitialFields],
  ) as SearchAcrossColumnsAndValuesResult

  const abortController = useRef<AbortController>()
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")
  const [searchResult, setSearchResult] =
    useState<SearchAcrossColumnsAndValuesResult | null>(initialFields)

  const [currentSelection, setCurrentSelection] =
    useState<SearchAcrossValuesSelection>([])
  const [not, setNot] = useState(false)

  const generatedCELRule = generateCELRule(currentSelection, not)

  const reset = () => {
    setSearchQuery("")
    setSearchResult(initialFields)
    setLoading(false)
    setCurrentSelection([])
    setNot(false)
  }

  const fetchOptions = (query: string) => {
    abortController.current?.abort()
    abortController.current = new AbortController()
    setLoading(true)

    getAsyncData(query, abortController.current.signal)
      .then((result) => {
        setSearchResult(result)
        setLoading(false)
        abortController.current = undefined
      })
      .catch(() => {})
  }

  let rightSection = null
  if (loading) {
    rightSection = <Loader size={18} />
  } else if (searchQuery) {
    rightSection = (
      <ActionIcon
        style={{
          alignSelf: "center",
        }}
        aria-label="Remove rule"
        onClick={reset}
      >
        <IconXboxXFilled />
      </ActionIcon>
    )
  }

  const treeItems = searchResult || initialFields

  return (
    <Flex direction="column" gap="sm">
      <header style={{ position: "relative" }}>
        <TextInput
          label="Search Context"
          placeholder="Enter a column name or a value"
          value={searchQuery}
          onChange={(event) => {
            const searchQuery = event.currentTarget.value
            setSearchQuery(event.currentTarget.value)
            if (!searchQuery.trim()) {
              setSearchResult(null)
              return
            }
            fetchOptions(event.currentTarget.value)
          }}
          onFocus={(e) => {
            if (searchResult === null && e.target.value.trim() != "") {
              fetchOptions(searchQuery)
            }
          }}
          rightSection={rightSection}
          style={{ flexGrow: "1" }}
        />
      </header>
      <div style={{ overflow: "hidden auto", maxHeight: "40vh" }}>
        {searchResult && (
          <ColumValueTree
            items={treeItems}
            currentSelection={currentSelection}
            setCurrentSelection={setCurrentSelection}
          />
        )}
      </div>
      {searchResult && searchResult.length > 0 ? (
        <Footer
          not={not}
          setNot={setNot}
          onInsert={onInsert}
          currentSelection={currentSelection}
          generatedCELRule={generatedCELRule}
        />
      ) : null}
    </Flex>
  )
}

export default SearchAcrossColumnsAndValues
