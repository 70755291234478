import { Drawer, Select, Stack, Tooltip, Text } from "@mantine/core"
import { useQuery } from "@tanstack/react-query"
import _ from "lodash"
import { useEffect, useMemo, useState } from "react"
import { RuleGroupType } from "react-querybuilder"

import { AxesResponses } from "@costory/types/endpoints/axes"

import { TableChartZoom } from "@costory/front/components/charts/TableExplorerZoom"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { apiClient } from "@costory/front/lib/apiClient"
import { useExplorerDrawer } from "@costory/front/queries/explore"
import { getBestDrillDown } from "@costory/front/utils/columns"
import { formatDatasources } from "@costory/front/utils/filters"

type Props = {
  opened: boolean
  index: number
  close: () => void
  rules: { query: RuleGroupType }[]
  preFillData: (index: number, groupBy: string, values: string[]) => void
}

type PropsPrefillExplorer = {
  axes: AxesResponses.Axis[]
  rules: {
    query: RuleGroupType
  }[]
  index: number
  preFillData: (groupBy: string, values: string[]) => void
}

const PrefillExplorerUI = ({
  axes,
  rules,
  index,
  preFillData,
}: PropsPrefillExplorer) => {
  // Getting groupBy dynamically based on the field selected in the rule using it's index.
  function getAxis() {
    const nbrQueryInRule = rules[index].query.rules.length - 1
    if (index - 1 >= 0) {
      const nbrQueryInRulePrevious = rules[index - 1].query.rules.length - 1
      return _.get(
        rules,
        `[${index - 1}].query.rules[${Math.min(nbrQueryInRulePrevious, nbrQueryInRule + 1)}].field`,
        "cos_provider",
      )
    }
    if (nbrQueryInRule > 0) {
      return getBestDrillDown(
        _.get(
          rules,
          `[${index}].query.rules[${nbrQueryInRule}].field`,
          "cos_provider",
        ),
      )
    }
    return "cos_provider"
  }
  const getBestGroupBy = getAxis()
  useEffect(() => {
    const usedFields = rules[index].query.rules.map((el) =>
      "field" in el ? el.field : "",
    )
    const candidates = [
      "cos_provider",
      "cos_service_name",
      "cos_sku",
      "cos_sub_account_id",
    ]
    const usedFieldsSet = new Set(usedFields)
    if (usedFields.indexOf(getBestGroupBy) > -1) {
      const options = new Set(candidates).difference(usedFieldsSet)
      const data = Array.from(options)
      if (data.length > 0) {
        setGroupBy(data[0])
        return
      }
    }
    setGroupBy(getBestGroupBy)
  }, [getBestGroupBy, index, rules])
  const [groupBy, setGroupBy] = useState<string>(getBestGroupBy)
  const groupByOptions = useMemo(() => formatDatasources(axes), [axes])

  const query = useExplorerDrawer({
    previousRules: rules.filter((_, idx) => idx <= index),
    ruleIndex: index,
    groupBy,
  })
  return (
    <>
      <Stack>
        <Select
          w={300}
          label="Group By"
          placeholder="Select group by"
          allowDeselect={false}
          data={groupByOptions}
          searchable
          onChange={(val) => setGroupBy(val!)}
          value={groupBy}
          key="groupBy"
        />
        <QueryWrapper query={query}>
          {({ data }) => (
            <TableChartZoom
              preFillData={(values) => preFillData(groupBy, values)}
              data={data.groupedByValue}
              currency="USD"
            />
          )}
        </QueryWrapper>
      </Stack>
    </>
  )
}

export const PrefillExplorerDrawer = ({
  opened,
  close,
  rules,
  index,
  preFillData,
}: Props) => {
  const axesQuery = useQuery({
    queryKey: ["axes"],
    queryFn: async () => {
      const response = await apiClient.get<AxesResponses.Axis[]>("/axes")
      return response.data
    },
  })

  return (
    <>
      <Drawer
        size="xl"
        opened={opened}
        onClose={close}
        title={
          <Tooltip
            label="Helps you quickly spot unallocated costs at a glance. Use various group-by options to analyze, and effortlessly incorporate these values into your rules"
            position="bottom"
            withArrow
            w={400}
            multiline={true}
          >
            <Text style={{ cursor: "pointer" }} size="md">
              Explore Cost
            </Text>
          </Tooltip>
        }
        position="right"
      >
        <QueryWrapper query={axesQuery}>
          {({ data: axes }) => (
            <PrefillExplorerUI
              preFillData={(groupBy, values) =>
                preFillData(index, groupBy, values)
              }
              axes={axes}
              rules={rules}
              index={index}
            />
          )}
        </QueryWrapper>
      </Drawer>
    </>
  )
}
