import {
  Accordion,
  ActionIcon,
  Button,
  Group,
  Input,
  Modal,
  Paper,
  SegmentedControl,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { useDisclosure, useUncontrolled } from "@mantine/hooks"
import {
  IconCloudSearch,
  IconExclamationCircle,
  IconGripVertical,
} from "@tabler/icons-react"
import { useFeatureFlag } from "configcat-react"
import _ from "lodash"
import { memo, useMemo } from "react"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"

import { VirtualDimensionsRequests } from "@costory/types/endpoints/virtualDimensions"

import { AxesSelector } from "@costory/front/components/AxesSelector"
import { QueryBuilder } from "@costory/front/components/QueryBuilder"
import { SelectCreatable } from "@costory/front/components/SelectCreatable"
import { AutoSplitTable } from "@costory/front/pages/VirtualDimensions/components/AutoSplitTable"
import { RuleEditorAccordionControl } from "@costory/front/pages/VirtualDimensions/components/RuleEditorAccordionControl"
import SplitCostResult from "@costory/front/pages/VirtualDimensions/components/SplitCostResult"
import { ALLOCATION_TYPES } from "@costory/front/pages/VirtualDimensions/constants"
import { formatNumber } from "@costory/front/utils/format"
import { showError } from "@costory/front/utils/validation"

type Props = {
  isExpanded: boolean
  onToggleAccordion: () => void
  onDuplicate: () => void
  form: UseFormReturnType<VirtualDimensionsRequests.EditVirtualDimension>
  ruleIndex: number
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined
  index: number
  allocatedCost: number | undefined
  exploreCost: () => void
}

export const RuleEditor = ({
  form,
  ruleIndex,
  isExpanded,
  onToggleAccordion,
  onDuplicate,
  dragHandleProps,
  index,
  allocatedCost,
  exploreCost,
}: Props) => {
  const [isOpen, { open, close }] = useDisclosure()

  const hasError = useMemo(() => {
    const formErrorsKeys = Object.keys(form.errors)
    return formErrorsKeys.some((key) => key.includes(`rules.${ruleIndex}`))
  }, [form.errors, ruleIndex])
  const rule = form.getValues().rules[ruleIndex]
  const allocation = rule.allocation
  const {
    value: dimentionValue,
    defaultValue,
    onChange,
  } = form.getInputProps(`rules.${ruleIndex}.allocation.dimensionValue`)

  const [value, setValue] = useUncontrolled({
    value: dimentionValue,
    defaultValue,
    finalValue: "Other",
    onChange,
  })

  const { value: isEnabled } = useFeatureFlag("autosplitcost", false)

  // Internal State issue: allocation type value doesn't update correctly on collapse/uncollapse
  const SegmentedControlComponent = memo(() => (
    <SegmentedControl
      data={ALLOCATION_TYPES.filter(
        ({ value }) => value !== "telemetry" || isEnabled,
      )}
      {...form.getInputProps(`rules.${ruleIndex}.allocation.allocationType`)}
      onChange={(val) => {
        switch (val) {
          case "splitCost": {
            form.setFieldValue(`rules.${ruleIndex}.allocation`, {
              allocationType: "splitCost",
              reAllocationParams: {
                partitions: [],
                type: "custom",
              },
            })
            form.validate()
            break
          }
          case "dimensionValue": {
            form.setFieldValue(`rules.${ruleIndex}.allocation`, {
              allocationType: "dimensionValue",
              dimensionValue: null,
            })
            form.validate()
            break
          }
          case "existingColumn": {
            form.setFieldValue(`rules.${ruleIndex}.allocation`, {
              allocationType: "existingColumn",
              existingColumn: null,
            })
            form.validate()
            break
          }
          case "telemetry": {
            form.setFieldValue(`rules.${ruleIndex}.allocation`, {
              allocationType: "telemetry",
              mappingParams: {
                datasource: "",
                mapping: {},
              },
            })
            form.validate()
            break
          }
        }
      }}
      key={form.key(`rules.${ruleIndex}.allocation.allocationType`)}
    />
  ))
  SegmentedControlComponent.displayName = "SegmentedControlComponent"

  const availableDimensionValues = _.uniq(form.getValues().values)

  return (
    <Paper>
      <Group wrap="nowrap" gap={0} justify="stretch">
        <ActionIcon {...dragHandleProps} variant="transparent" c="gray.3">
          <IconGripVertical />
        </ActionIcon>
        <Accordion
          w="98%"
          onChange={onToggleAccordion}
          value={isExpanded ? "open" : "closed"}
          chevronPosition="left"
        >
          <Accordion.Item w="100%" value="open" style={{ border: "none" }}>
            <RuleEditorAccordionControl
              onRename={open}
              onDuplicate={onDuplicate}
              onDelete={() => form.removeListItem("rules", ruleIndex)}
            >
              <Group justify="space-between">
                <Group gap={4}>
                  {hasError && (
                    <Tooltip
                      label={
                        <>
                          {showError({
                            prefix: `rules.${index}`,
                            errors: form.errors,
                          }).join(" ")}
                        </>
                      }
                    >
                      <ThemeIcon c="red.5">
                        <IconExclamationCircle />
                      </ThemeIcon>
                    </Tooltip>
                  )}

                  <Text size="lg" fw="bold" c={hasError ? "red.5" : "inherit"}>
                    Rule {index}:{" "}
                    {rule.name.length > 0
                      ? rule.name
                      : rule.allocation.allocationType == "dimensionValue"
                        ? rule.allocation.dimensionValue
                        : ""}
                  </Text>
                </Group>
                <Text>
                  {allocatedCost ? formatNumber(allocatedCost, "currency") : ""}
                </Text>
              </Group>
            </RuleEditorAccordionControl>
            <Accordion.Panel>
              <Stack>
                <QueryBuilder
                  {...form.getInputProps(`rules.${ruleIndex}.query`)}
                  key={form.key(`rules.${ruleIndex}.query`)}
                />
                <Group justify="space-between">
                  <Group>
                    <SegmentedControlComponent />
                    {allocation.allocationType == "dimensionValue" && (
                      <SelectCreatable
                        key={form.key(
                          `rules.${ruleIndex}.allocation.dimensionValue`,
                        )}
                        data={availableDimensionValues}
                        {...form.getInputProps(
                          `rules.${ruleIndex}.allocation.dimensionValue`,
                        )}
                        handleCreate={(val) =>
                          form.insertListItem("values", val)
                        }
                        value={value}
                        onChange={setValue}
                        error={
                          form.getInputProps(
                            `rules.${ruleIndex}.allocation.dimensionValue`,
                          ).error !== undefined
                        }
                      />
                    )}
                    {allocation.allocationType == "existingColumn" && (
                      <AxesSelector
                        {...form.getInputProps(
                          `rules.${ruleIndex}.allocation.existingColumn`,
                        )}
                        key={form.key(
                          `rules.${ruleIndex}.allocation.existingColumn`,
                        )}
                        error={
                          form.getInputProps(
                            `rules.${ruleIndex}.allocation.existingColumn`,
                          ).error !== undefined
                        }
                      />
                    )}
                  </Group>
                  <Tooltip
                    w={400}
                    multiline={true}
                    label="Helps you quickly spot unallocated costs at a glance. Use various group-by options to analyze, and effortlessly incorporate these values into your rules"
                  >
                    <Button
                      variant="outline"
                      size="sm"
                      leftSection={<IconCloudSearch />}
                      onClick={() => exploreCost()}
                    >
                      Explore costs
                    </Button>
                  </Tooltip>
                </Group>
                {allocation.allocationType === "splitCost" && (
                  <SplitCostResult
                    allocation={allocation}
                    prefixForm={`rules.${index}.allocation`}
                    form={form}
                    costRule={allocatedCost ?? 0}
                    vdDict={availableDimensionValues}
                  />
                )}
                {allocation.allocationType === "telemetry" && (
                  <AutoSplitTable
                    ruleCost={allocatedCost ?? 0}
                    availableDimensionValues={availableDimensionValues}
                    onChange={({ businessMetricId, virtualDimensionValue }) =>
                      form.setFieldValue(
                        `rules.${index}.allocation.mappingParams.mapping.${businessMetricId}`,
                        virtualDimensionValue,
                      )
                    }
                    values={allocation.mappingParams.mapping}
                    onCreate={(value) => form.insertListItem("values", value)}
                    onSelectDataSource={(datasource, businessMetrics) => {
                      form.setFieldValue(
                        `rules.${index}.allocation.mappingParams.datasource`,
                        datasource,
                      )
                      form.setFieldValue(
                        `rules.${index}.allocation.mappingParams.mapping`,
                        businessMetrics.reduce((acc, curr) => {
                          _.set(acc, curr, "")
                          return acc
                        }, {}),
                      )
                    }}
                  />
                )}
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Group>
      <Modal opened={isOpen} onClose={close} title="Rename a rule" withinPortal>
        <Group>
          <Input
            {...form.getInputProps(`rules.${ruleIndex}.name`)}
            flex={1}
            onKeyDown={(key) => (key.key == "Enter" ? close() : null)}
          />
          <Button onClick={close}>Save</Button>
        </Group>
      </Modal>
    </Paper>
  )
}
