import { Button, Fieldset, Text, Tooltip } from "@mantine/core"

export type QueryBuilderPickerType = "regular" | "cel"

const QueryBuilderPicker = ({
  current,
  onChange,
}: {
  current: QueryBuilderPickerType
  onChange: (value: QueryBuilderPickerType) => void
}) => {
  return (
    <>
      <Tooltip.Group openDelay={500} closeDelay={100}>
        <Fieldset radius="xs" variant="unstyled">
          <Text role="legend" fz="xs" display="inline-block" mr={4}>
            Query builder:
          </Text>
          <Button.Group display="inline-block">
            <Button
              size="compact-xs"
              variant={current === "regular" ? "filled" : "outline"}
              onClick={() => {
                onChange("regular")
              }}
            >
              <Tooltip label="Regular query builder">
                <span>Regular</span>
              </Tooltip>
            </Button>
            <Button
              size="compact-xs"
              variant={current === "cel" ? "filled" : "outline"}
              onClick={() => {
                onChange("cel")
              }}
            >
              <Tooltip label="Common Expression Language">
                <span>CEL</span>
              </Tooltip>
            </Button>
          </Button.Group>
        </Fieldset>
      </Tooltip.Group>
    </>
  )
}

export default QueryBuilderPicker
