import { GridChartBaseProps } from "@mantine/charts"
import {
  Checkbox,
  Flex,
  NumberInput,
  Select,
  Stack,
  ThemeIcon,
  Tooltip,
} from "@mantine/core"
import { useDebouncedCallback, useDisclosure } from "@mantine/hooks"
import { IconHelpCircle } from "@tabler/icons-react"
import { useFeatureFlag } from "configcat-react"
import dayjs from "dayjs"
import _ from "lodash"
import { useState } from "react"
import { ReferenceLinePosition } from "recharts/types/cartesian/ReferenceLine"
import { CategoricalChartState } from "recharts/types/chart/types"
import { LabelPosition } from "recharts/types/component/Label"

import { EventsResponses } from "@costory/types/endpoints/events"
import { Filters } from "@costory/types/filters"
import { ExplorerChartType } from "@costory/types/prisma-client"

import { AreaChart } from "@costory/front/components/charts/AreaChart"
import { LineChart } from "@costory/front/components/charts/LineChart"
import { StackedBarChart } from "@costory/front/components/charts/StackedBarChart"
import { WaterfallChart } from "@costory/front/components/charts/WaterfallChart"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { ModalZoomOnEvents } from "@costory/front/pages/Events/ModalZoomOnEvents"
import { NewEventForm } from "@costory/front/pages/Events/NewEventForm"
import { useEventsQuery } from "@costory/front/queries/explore"
import { getBestDrillDown } from "@costory/front/utils/columns"

export type HandlerEvents = {
  eventClickOnChart: (
    events: EventsResponses.Aggregated[],
    val: CategoricalChartState,
  ) => void
  createEventFromXAxisClick: (event: { value: string }) => void
  eventDefaultDate: Date
  selectedEvents: EventsResponses.Aggregated | undefined
  openedSelectedEvents: boolean
  openedNewEvent: boolean
}

type Props = {
  filters: Filters
  height?: number
  withLegend?: boolean
  explorerChartType?: ExplorerChartType
  drillDownInto?: (groupBy: string, value: string, newGroupBy: string) => void
  setExplorerChartType?: (chartType: ExplorerChartType) => void
  referenceLines?: GridChartBaseProps["referenceLines"]
  setThreshold?: (value: number | null) => void
  threshold?: number | null
}

export const ExplorerMainChart = (props: Props) => {
  const chartOptions = Object.values(ExplorerChartType).map((type) => ({
    value: type,
    label: _.capitalize(type),
  }))
  const [checked, setChecked] = useState(!!props.threshold)
  const onChangeDebounced = useDebouncedCallback(
    (val) => props.setThreshold && props.setThreshold(val),
    800,
  )
  const [eventDefaultDate, setEventDate] = useState(new Date())
  const [selectedEvents, setSelectedEvents] =
    useState<EventsResponses.Aggregated>()
  const [openedSelectedEvents, handlersOpenSelectedEvents] =
    useDisclosure(false)
  const [openedNewEvent, handlersNewEventModal] = useDisclosure(false)
  const featureFlagEvents = useFeatureFlag("events", false)
  const interactionDisabled = !featureFlagEvents.value
  function createEventFromXAxisClick(event: { value: string }) {
    if (interactionDisabled) {
      return
    }
    const date = event.value
    setEventDate(dayjs(date).toDate())
    handlersNewEventModal.open()
  }
  function eventClickOnChart(
    events: EventsResponses.Aggregated[],
    val: CategoricalChartState,
  ) {
    if (interactionDisabled) {
      return
    }
    const filteredEvents = events.filter((el) => el.date === val.activeLabel!)
    // if click on Chart where there are events open modal
    if (filteredEvents.length > 0) {
      setSelectedEvents(filteredEvents[0])
      handlersOpenSelectedEvents.open()
    }
  }
  const handlerEvents = {
    eventClickOnChart,
    createEventFromXAxisClick,
    eventDefaultDate,
    selectedEvents,
    openedSelectedEvents,
    openedNewEvent,
  }
  const eventsQuery = useEventsQuery(props.filters)

  return (
    <QueryWrapper query={eventsQuery} allowEmptyArray>
      {({ data: events }) => {
        const referencedLinesEvents = featureFlagEvents.value
          ? parseEventsToReferenceLines(events)
          : []
        return (
          <>
            {openedNewEvent && (
              <NewEventForm
                isOpen={openedNewEvent}
                onClose={handlersNewEventModal.close}
                defaultDate={eventDefaultDate}
              />
            )}
            {selectedEvents && (
              <ModalZoomOnEvents
                events={selectedEvents}
                isOpened={openedSelectedEvents}
                onClose={handlersOpenSelectedEvents.close}
              />
            )}
            <Stack h="100%">
              <Flex gap={15} direction="row-reverse">
                {props.explorerChartType && (
                  <Select
                    w={120}
                    data={chartOptions}
                    value={props.explorerChartType}
                    onChange={(value) =>
                      value &&
                      props.setExplorerChartType?.(value as ExplorerChartType)
                    }
                  />
                )}
                {props.explorerChartType === ExplorerChartType.WATERFALL && (
                  <Flex align="center" gap={15}>
                    {checked && (
                      <NumberInput
                        value={Number(props.threshold)}
                        onChange={(e) => onChangeDebounced(+e)}
                      />
                    )}
                    {props.setThreshold && (
                      <Flex>
                        <Checkbox
                          label="Threshold"
                          checked={checked}
                          onChange={(event) => {
                            setChecked(event.currentTarget.checked),
                              !event.currentTarget.checked &&
                                props.setThreshold &&
                                props.setThreshold(null)
                          }}
                        />
                        <Tooltip
                          w={400}
                          multiline={true}
                          label="Combine all items with an absolute value below this threshold."
                        >
                          <ThemeIcon
                            style={{ cursor: "pointer" }}
                            c="primary.6"
                            size="sm"
                          >
                            <IconHelpCircle />
                          </ThemeIcon>
                        </Tooltip>
                      </Flex>
                    )}
                  </Flex>
                )}
              </Flex>
              <Graph
                {...props}
                referenceLines={
                  props.referenceLines
                    ? props.referenceLines.concat(referencedLinesEvents || [])
                    : referencedLinesEvents
                }
                handlerEvents={handlerEvents}
                events={events}
              />
            </Stack>
          </>
        )
      }}
    </QueryWrapper>
  )
}
function Graph({
  filters,
  height,
  explorerChartType,
  withLegend = true,
  drillDownInto = () => {},
  referenceLines,
  threshold,
  handlerEvents,
  events,
}: Props & {
  handlerEvents: HandlerEvents
  events: EventsResponses.Aggregated[]
}) {
  switch (explorerChartType) {
    case ExplorerChartType.LINE: {
      return (
        <>
          <LineChart
            height={height}
            filters={filters}
            withLegend={withLegend}
          />
        </>
      )
    }
    case ExplorerChartType.WATERFALL: {
      return (
        <WaterfallChart
          height={height}
          filters={{ ...filters, threshold }}
          withLegend={withLegend}
          dashboardChartConfig={75}
        />
      )
    }
    case ExplorerChartType.AREA: {
      return (
        <AreaChart height={height} filters={filters} withLegend={withLegend} />
      )
    }
    default: {
      return (
        <StackedBarChart
          handlerEvents={handlerEvents}
          height={height}
          filters={filters}
          withLegend={withLegend}
          events={events}
          drillDownInto={(value) =>
            drillDownInto(
              filters.groupBy,
              value,
              getBestDrillDown(filters.groupBy),
            )
          }
          referenceLines={referenceLines}
        />
      )
    }
  }
}

function parseEventsToReferenceLines(
  events: EventsResponses.Aggregated[],
): GridChartBaseProps["referenceLines"] {
  return events.map((el) => ({
    x: el.date,
    label: el.events.map((el) => el.name).join(", "),
    labelPosition: "insideTopLeft" as LabelPosition,
    strokeWidth: 2,
    position: "start" as ReferenceLinePosition,
    stroke: "var(--mantine-color-cyan-6)",
  }))
}
