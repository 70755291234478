import {
  ActionIcon,
  Button,
  Divider,
  Group,
  Loader,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
  useTree,
} from "@mantine/core"
import { useField } from "@mantine/form"
import { useDebouncedValue, useToggle } from "@mantine/hooks"
import { openConfirmModal } from "@mantine/modals"
import { showNotification } from "@mantine/notifications"
import {
  IconChartBarOff,
  IconChartBarPopular,
  IconCursorText,
  IconFolderPlus,
  IconLayout,
  IconLayoutOff,
  IconList,
  IconListTree,
  IconSearch,
  IconTrash,
  IconX,
} from "@tabler/icons-react"
import _ from "lodash"
import { FC, useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { TreeNodeType } from "@costory/types/endpoints/folders"
import { ComponentType } from "@costory/types/prisma-client"
import { User } from "@costory/types/user"

import { ROOT_FOLDER_ID } from "@costory/shared/const"

import { DefaultFileLeaf } from "@costory/front/components/FileExplorer/DefaultFileLeaf"
import { DefaultFolderNode } from "@costory/front/components/FileExplorer/DefaultFolderNode"
import { FileExplorerHeader } from "@costory/front/components/FileExplorer/FileExplorerHeader"
import { SearchExplorer } from "@costory/front/components/FileExplorer/FoldersSearch"
import {
  DashboardLeafProps,
  SavedViewLeafProps,
  TreeExplorer,
} from "@costory/front/components/FileExplorer/TreeExplorer"
import { useAuthState } from "@costory/front/queries/auth"
import {
  useDeleteDashboard,
  useRenameDashboard,
} from "@costory/front/queries/dashboard"
import {
  useCreateFolder,
  useDeleteFolder,
  useRenameFolder,
} from "@costory/front/queries/folders"
import {
  useDeleteSavedView,
  useRenameSavedView,
} from "@costory/front/queries/savedViews"

const COSTORY_ORGID = import.meta.env.VITE_COSTORY_ORGID
const COSTORY_EMAIL_DOMAIN = import.meta.env.VITE_COSTORY_EMAIL_DOMAIN

const isAllowedToEdit = (
  node: {
    id: string
    label: string
    type: TreeNodeType
    closestFolderId: string | null
    ownerId: string
    componentType?: ComponentType
  },
  user: User,
) => {
  if (node.componentType === ComponentType.COMMUNITY) {
    return (
      COSTORY_ORGID === user.currentOrg.id &&
      user.email.endsWith(COSTORY_EMAIL_DOMAIN) &&
      user.isAdmin
    )
  }
  return node.ownerId === user.id || user.isAdmin
}

interface FileExplorerProps {
  onOpenDashboard?: (id: string) => void
  onOpenSavedView?: (id: string) => void
  onOpenFolder?: (id: string) => void
  onDoubleClick: (id: string, type: TreeNodeType) => void
  buttonProps?: {
    label?: string
    disabled?: boolean
    loading?: boolean
  }
  defaultShouldShowSavedViews?: boolean
  defaultShouldShowDashboards?: boolean
  optionNewDashboardButton?: boolean
}

export const FileExplorer: FC<FileExplorerProps> = ({
  onOpenDashboard,
  onOpenSavedView,
  onOpenFolder,
  onDoubleClick,
  optionNewDashboardButton,
  defaultShouldShowDashboards = true,
  defaultShouldShowSavedViews = true,
  buttonProps: { label, disabled, loading } = {
    label: "Open",
    disabled: false,
    loading: false,
  },
}) => {
  const { user } = useAuthState()
  const [shouldShowSavedViews, toggleShouldShowSavedView] = useToggle([
    defaultShouldShowSavedViews,
    !defaultShouldShowSavedViews,
  ])
  const [shouldShowDashboards, toggleShouldShowDashboards] = useToggle([
    defaultShouldShowDashboards,
    !defaultShouldShowDashboards,
  ])
  const tree = useTree({
    initialExpandedState: { [ROOT_FOLDER_ID]: true },
  })
  const [search, setSearch] = useState("")
  const searchField = useField({ initialValue: search })
  const nameField = useField({ initialValue: "", mode: "uncontrolled" })
  const folderNameField = useField({ initialValue: "", mode: "uncontrolled" })
  const [debouncedSearch] = useDebouncedValue(searchField.getValue(), 300)
  const [selectedNode, setSelectedNode] = useState<{
    id: string
    label: string
    type: TreeNodeType
    closestFolderId: string | null
    ownerId: string
    componentType?: ComponentType
  } | null>(null)

  const isOpenButtonDisabled =
    !selectedNode ||
    (selectedNode.type === TreeNodeType.Folder && !onOpenFolder) ||
    (selectedNode.type === TreeNodeType.Dashboard && !onOpenDashboard) ||
    (selectedNode.type === TreeNodeType.SavedView && !onOpenSavedView)

  useEffect(() => {
    setSearch(debouncedSearch)
  }, [debouncedSearch])

  const {
    mutateAsync: deleteDashboard,
    isPending: isDeleteDashboardMutationPending,
  } = useDeleteDashboard()
  const {
    mutateAsync: deleteSavedView,
    isPending: isDeleteSavedViewMutationPending,
  } = useDeleteSavedView()
  const {
    mutateAsync: deleteFolder,
    isPending: isDeleteFolderMutationPending,
  } = useDeleteFolder()

  const {
    mutateAsync: renameDashboard,
    isPending: isRenameDashboardMutationPending,
  } = useRenameDashboard()
  const {
    mutateAsync: renameSavedView,
    isPending: isRenameSavedViewMutationPending,
  } = useRenameSavedView()
  const {
    mutateAsync: renameFolder,
    isPending: isRenameFolderMutationPending,
  } = useRenameFolder()

  const {
    mutateAsync: createFolder,
    isPending: isCreateFolderMutationPending,
  } = useCreateFolder()

  const handleSelect = (
    id: string,
    label: string,
    type: TreeNodeType,
    closestFolderId: string | null,
    ownerId: string,
    componentType?: ComponentType,
  ) => {
    if (selectedNode?.id === id) {
      setSelectedNode(null)
      return
    }
    setSelectedNode({
      id,
      label,
      type,
      closestFolderId,
      ownerId,
      componentType,
    })
  }

  const handleClickOpenSelectedFile = () => {
    if (selectedNode?.type === TreeNodeType.Dashboard) {
      onOpenDashboard?.(selectedNode.id)
    }
    if (selectedNode?.type === TreeNodeType.SavedView) {
      onOpenSavedView?.(selectedNode.id)
    }
    if (selectedNode?.type === TreeNodeType.Folder) {
      onOpenFolder?.(selectedNode.id)
    }
  }

  const handleClickDeleteSelectedFile = () => {
    openConfirmModal({
      title: <Title>Are you sure ?</Title>,
      children: (
        <Text>
          Do you really want to delete{" "}
          {selectedNode!.type === TreeNodeType.Dashboard
            ? "dashboard"
            : "saved view"}{" "}
          <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
            {selectedNode!.label}
          </span>{" "}
          ?
        </Text>
      ),
      confirmProps: {
        color: "red",
        loading:
          isDeleteDashboardMutationPending ||
          isDeleteSavedViewMutationPending ||
          isDeleteFolderMutationPending,
      },
      labels: {
        confirm: "Delete",
        cancel: "Cancel",
      },
      onConfirm: async () => {
        if (!selectedNode) {
          return
        }
        try {
          if (selectedNode.type === TreeNodeType.Dashboard) {
            await deleteDashboard(selectedNode.id)
          }
          if (selectedNode.type === TreeNodeType.SavedView) {
            await deleteSavedView(selectedNode.id)
          }
          if (selectedNode.type === TreeNodeType.Folder) {
            await deleteFolder(selectedNode.id)
          }
          showNotification({
            title: "Success",
            message: `${selectedNode.type} successfully deleted`,
            color: "green",
          })
          setSelectedNode(null)
        } catch {
          showNotification({
            title: "Error",
            message: "An error occured while deleting the file",
            color: "red",
          })
        }
      },
    })
  }

  const handleClickCreateFolder = () => {
    if (!selectedNode) {
      return
    }

    if (selectedNode.type === TreeNodeType.Folder) {
      tree.expand(selectedNode!.closestFolderId!)
    }

    folderNameField.setValue("")

    openConfirmModal({
      title: <Title>Create folder</Title>,
      children: (
        <TextInput
          {...folderNameField.getInputProps()}
          placeholder="Enter folder name"
        />
      ),
      confirmProps: {
        loading: isCreateFolderMutationPending,
      },
      labels: {
        confirm: "Create",
        cancel: "Cancel",
      },
      onConfirm: async () => {
        const name = folderNameField.getValue()
        if (!name) {
          return
        }
        try {
          const createdFolder = await createFolder({
            name,
            parentFolderId: selectedNode.closestFolderId,
          })
          tree.select(createdFolder.id)
          setSelectedNode({
            id: createdFolder.id,
            label: createdFolder.name,
            type: TreeNodeType.Folder,
            closestFolderId: createdFolder.parentFolderId,
            ownerId: createdFolder.createdById,
          })
          showNotification({
            title: "Success",
            message: "Folder successfully created",
            color: "green",
          })
        } catch {
          showNotification({
            title: "Error",
            message: "An error occured while creating the folder",
            color: "red",
          })
        }
      },
    })
  }

  const handleClickRenameSelectedNode = () => {
    if (!selectedNode) {
      return
    }

    nameField.setValue(selectedNode.label)

    openConfirmModal({
      title: <Title>Rename {selectedNode.type}</Title>,
      children: (
        <TextInput
          {...nameField.getInputProps()}
          placeholder="Enter new name"
        />
      ),
      confirmProps: {
        loading:
          isRenameDashboardMutationPending ||
          isRenameSavedViewMutationPending ||
          isRenameFolderMutationPending,
      },
      labels: {
        confirm: "Rename",
        cancel: "Cancel",
      },
      onConfirm: async () => {
        if (!selectedNode) {
          return
        }
        try {
          if (selectedNode.type === TreeNodeType.Dashboard) {
            await renameDashboard({
              id: selectedNode.id,
              name: nameField.getValue(),
            })
          }
          if (selectedNode.type === TreeNodeType.SavedView) {
            await renameSavedView({
              id: selectedNode.id,
              name: nameField.getValue(),
            })
          }
          if (selectedNode.type === TreeNodeType.Folder) {
            await renameFolder({
              id: selectedNode.id,
              name: nameField.getValue(),
            })
          }
          showNotification({
            title: "Success",
            message: `${selectedNode.type} successfully renamed`,
            color: "green",
          })
        } catch {
          showNotification({
            title: "Error",
            message: "An error occured while renaming the file",
            color: "red",
          })
        }
      },
    })
  }

  const renderLeaf = ({
    show,
    ...payload
  }: (DashboardLeafProps | SavedViewLeafProps) & { show: boolean }) => {
    const clonedProps = _.cloneDeep(payload)
    _.set(clonedProps, "node.nodeProps.onSelect", () => {
      const {
        value,
        label,
        nodeProps: { type, parentFolderId, ownerId, componentType },
      } = clonedProps.node
      handleSelect(
        value,
        label as string,
        type,
        parentFolderId,
        ownerId,
        componentType,
      )
      tree.toggleSelected(payload.node.value)
    })
    const props = _.set(clonedProps, "node.nodeProps.onDoubleClick", () => {
      onDoubleClick(payload.node.value, payload.node.nodeProps.type)
    })

    return show ? <DefaultFileLeaf {...props} /> : null
  }

  return (
    <Stack h="90%">
      <Group justify="space-between">
        <Group>
          <Group
            bg="gray.1"
            gap={4}
            style={{ borderRadius: 8, padding: 4 }}
            justify="space-around"
          >
            <Tooltip label="Filter out saved views">
              <ActionIcon onClick={() => toggleShouldShowSavedView()}>
                {shouldShowSavedViews ? (
                  <IconChartBarPopular color="var(--mantine-color-primary-6)" />
                ) : (
                  <IconChartBarOff color="var(--mantine-color-gray-5)" />
                )}
              </ActionIcon>
            </Tooltip>
            <Divider orientation="vertical" />
            <Tooltip label="Filter out dashboards">
              <ActionIcon onClick={() => toggleShouldShowDashboards()}>
                {shouldShowDashboards ? (
                  <IconLayout color="var(--mantine-color-primary-6)" />
                ) : (
                  <IconLayoutOff color="var(--mantine-color-gray-5)" />
                )}
              </ActionIcon>
            </Tooltip>
          </Group>
          <Group
            bg="gray.1"
            gap={4}
            style={{ borderRadius: 8 }}
            p={4}
            justify="space-around"
          >
            <Tooltip label="Collapse all">
              <ActionIcon
                onClick={() => {
                  tree.collapseAllNodes()
                  tree.expand(ROOT_FOLDER_ID)
                }}
              >
                <IconList color="var(--mantine-color-primary-6)" />
              </ActionIcon>
            </Tooltip>
            <Divider orientation="vertical" />
            <Tooltip label="Expand all">
              <ActionIcon onClick={() => tree.expandAllNodes()}>
                <IconListTree color="var(--mantine-color-primary-6)" />
              </ActionIcon>
            </Tooltip>
          </Group>
          <TextInput
            leftSection={<IconSearch />}
            placeholder="Search by name"
            {...searchField.getInputProps()}
            rightSection={
              !!search && (
                <ActionIcon onClick={() => searchField.setValue("")}>
                  <IconX />
                </ActionIcon>
              )
            }
          />
        </Group>
        <Group>
          {optionNewDashboardButton && (
            <Button>
              <Link to="/dashboards/new"> New Dashboard </Link>
            </Button>
          )}

          <Group bg="gray.1" gap={4} style={{ borderRadius: 8 }} p={4}>
            <Tooltip label={`Rename ${selectedNode?.type}`}>
              <ActionIcon
                disabled={
                  !selectedNode || !isAllowedToEdit(selectedNode, user!)
                }
                onClick={handleClickRenameSelectedNode}
                color="primary"
              >
                {isRenameFolderMutationPending ||
                isRenameDashboardMutationPending ||
                isRenameSavedViewMutationPending ? (
                  <Loader size="sm" />
                ) : (
                  <IconCursorText />
                )}
              </ActionIcon>
            </Tooltip>
            <Divider orientation="vertical" />
            <Tooltip label="Add folder">
              <ActionIcon
                disabled={
                  !selectedNode ||
                  !!searchField.getValue() ||
                  !isAllowedToEdit(selectedNode, user!)
                }
                onClick={handleClickCreateFolder}
                color="primary"
              >
                {isDeleteDashboardMutationPending ||
                isDeleteSavedViewMutationPending ? (
                  <Loader size="sm" />
                ) : (
                  <IconFolderPlus />
                )}
              </ActionIcon>
            </Tooltip>
          </Group>
          <Group
            bg={
              selectedNode && isAllowedToEdit(selectedNode, user!)
                ? "red"
                : "gray.1"
            }
            gap={4}
            style={{ borderRadius: 8 }}
            p={4}
          >
            <Tooltip label={`Delete selected ${selectedNode?.type}`}>
              <ActionIcon
                disabled={
                  selectedNode && isAllowedToEdit(selectedNode, user!)
                    ? false
                    : true
                }
                onClick={handleClickDeleteSelectedFile}
                variant="filled"
                color="red"
              >
                {isDeleteDashboardMutationPending ||
                isDeleteSavedViewMutationPending ? (
                  <Loader size="sm" />
                ) : (
                  <IconTrash />
                )}
              </ActionIcon>
            </Tooltip>
          </Group>
        </Group>
      </Group>
      <Stack h="90%">
        <Stack
          h="100%"
          styles={{
            root: {
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "var(--mantine-color-gray-2)",
              borderRadius: 8,
            },
          }}
        >
          <FileExplorerHeader />
          {search ? (
            <SearchExplorer
              search={search}
              onSelect={(
                id,
                label,
                type,
                closestFolderId,
                ownerId,
                componentType,
              ) =>
                setSelectedNode({
                  id,
                  type,
                  label,
                  closestFolderId,
                  ownerId,
                  componentType,
                })
              }
              shouldShowSavedViews={shouldShowSavedViews}
              shouldShowDashboards={shouldShowDashboards}
            />
          ) : (
            <TreeExplorer
              tree={tree}
              renderSavedViewLeaf={(payload) =>
                renderLeaf({ ...payload, show: shouldShowSavedViews })
              }
              renderDashboardLeaf={(payload) =>
                renderLeaf({ ...payload, show: shouldShowDashboards })
              }
              renderFolderNode={(payload) => {
                const clonedProps = _.cloneDeep(payload)
                _.set(clonedProps, "node.nodeProps.onSelect", () => {
                  const {
                    value,
                    label,
                    nodeProps: { type, ownerId },
                  } = clonedProps.node
                  const hasCommunityComponent = _.find(
                    clonedProps.node.children,
                    {
                      nodeProps: { componentType: ComponentType.COMMUNITY },
                    },
                  )
                  const componentType = hasCommunityComponent
                    ? ComponentType.COMMUNITY
                    : undefined

                  handleSelect(
                    value,
                    label as string,
                    type,
                    value,
                    ownerId,
                    componentType,
                  )
                  tree.toggleSelected(clonedProps.node.value)
                })
                const props = _.set(
                  clonedProps,
                  "node.nodeProps.onDoubleClick",
                  () => {
                    onDoubleClick(
                      payload.node.value,
                      payload.node.nodeProps.type,
                    )
                  },
                )

                return <DefaultFolderNode {...props} />
              }}
            />
          )}
        </Stack>
      </Stack>
      <Group justify="end">
        <Button
          disabled={isOpenButtonDisabled || disabled}
          loading={loading}
          onClick={handleClickOpenSelectedFile}
          styles={{
            root: { cursor: isOpenButtonDisabled ? "default" : "pointer" },
          }}
        >
          {label}
        </Button>
      </Group>
    </Stack>
  )
}
