import { Checkbox } from "@mantine/core"

import { SearchAcrossValuesSelection } from "../types"

interface ValueCheckboxProps {
  label: string
  name: string
  column: string
  currentSelection: SearchAcrossValuesSelection
  onCheck: (value: string, column: string) => void
  onUncheck: (value: string, column: string) => void
}

export const ValueCheckbox = ({
  label,
  name,
  column,
  currentSelection,
  onCheck,
  onUncheck,
}: ValueCheckboxProps) => {
  const isCurrentlySelected = !!currentSelection.find(
    (item) =>
      item.type === "value" && item.column === column && item.value === name,
  )
  const isOtherValueOfSelectedColumn = !!currentSelection.find(
    (item) =>
      item.type === "value" && item.column === column && item.value !== name,
  )

  return (
    <Checkbox
      disabled={
        currentSelection.length > 0 &&
        !isCurrentlySelected &&
        !isOtherValueOfSelectedColumn
      }
      label={label}
      name={name}
      checked={isCurrentlySelected}
      onChange={(e) => {
        if (e.currentTarget.checked) {
          onCheck(name, column)
        } else {
          onUncheck(name, column)
        }
      }}
    />
  )
}
