import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom"

import { PageNotFound } from "@costory/front/components/PageNotFound"
import {
  AuthGuard,
  AuthGuardWithoutOrganization,
} from "@costory/front/components/auth/AuthGuard"
import { AppLayout } from "@costory/front/components/layout/AppLayout"
import { ForceColorScheme } from "@costory/front/components/layout/ForceColorScheme"
import { AlertCreationPage } from "@costory/front/pages/Alerts/AlertCreationPage"
import { AlertEditionPage } from "@costory/front/pages/Alerts/AlertEditionPage"
import { AlertsListPage } from "@costory/front/pages/Alerts/AlertListPage"
import { BudgetCreationPage } from "@costory/front/pages/Budgets/BudgetCreationPage"
import { BudgetEditionPage } from "@costory/front/pages/Budgets/BudgetEditionPage"
import { BudgetRevisionPage } from "@costory/front/pages/Budgets/BudgetRevisionPage"
import { BudgetPage } from "@costory/front/pages/Budgets/BudgetViewPage"
import { BudgetsListPage } from "@costory/front/pages/Budgets/BudgetsListPage"
import { DashboardEditionPage } from "@costory/front/pages/Dashboards/DashboardEditionPage"
import { DigestPage } from "@costory/front/pages/Digest"
import { EditDigest } from "@costory/front/pages/DigestV2/EditDigest"
import { ListPageWrapper } from "@costory/front/pages/DigestV2/ListPage"
import { NewDigest } from "@costory/front/pages/DigestV2/NewDigest"
import { EmbedPage } from "@costory/front/pages/EmbedExplorer"
import { EventsPage } from "@costory/front/pages/Events"
import { InitiativePage } from "@costory/front/pages/Initiatives/InitiativePage"
import { InitiativeListPage } from "@costory/front/pages/Initiatives/InitiativesListPage"
import { IntegrationPage } from "@costory/front/pages/Integration"
import { SlackOAuthRedirect } from "@costory/front/pages/Integration/SlackOAuthRedirect"
import { LabelsExplorerPage } from "@costory/front/pages/LabelsExplorer"
import { MetricsPage } from "@costory/front/pages/Metrics"
import { ReportsPage } from "@costory/front/pages/Reports/ReportsExplorerPage"
import { TagsPage } from "@costory/front/pages/Tags"
import { VdimHelperPage } from "@costory/front/pages/VirtualDimHelper"
import { EditVirtualDimensionVersion } from "@costory/front/pages/VirtualDimensions/EditVirtualDimensionVersion"
import { JobExecutionsPage } from "@costory/front/pages/VirtualDimensions/JobExecutions"

import { DashboardCreationPage } from "./Dashboards/DashboardCreationPage"
import { DataHealthPage } from "./DataHealth"
import { DatasourcesPage } from "./Datasources"
import { ExplorerPage } from "./Explorer"
import { HomePage } from "./Home/HomePage"
import { InsightsPage } from "./Insights"
import { SavedViewsPage } from "./SavedViews"
import { SettingsPage } from "./Settings"
import { VirtualDimensionsPage } from "./VirtualDimensions"
import { EditVirtualDimensionPage } from "./VirtualDimensions/EditVirtualDimension"
import { NewVirtualDimensionPage } from "./VirtualDimensions/NewVirtualDimension"
import { WaterfallPage } from "./Waterfall"

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<PageNotFound />}>
      <Route path="org-selection" element={<AuthGuardWithoutOrganization />} />
      <Route element={<AuthGuard />}>
        <Route path="embed">
          <Route path=":orgId/:chartType" element={<EmbedPage />} />
        </Route>
        <Route element={<AppLayout />}>
          <Route index element={<Navigate to="/home" replace />} />
          <Route path="home" element={<HomePage />} />
          <Route path="reports" element={<ReportsPage />} />
          <Route path="dashboards">
            <Route path="new" element={<DashboardCreationPage />} />
            <Route path=":dashboardId" element={<DashboardEditionPage />} />
          </Route>
          <Route path="explorer">
            <Route index element={<ExplorerPage />} />
            <Route path="views/:viewId" element={<ExplorerPage />} />
          </Route>
          <Route path="waterfall">
            <Route index element={<WaterfallPage />} />
            <Route path="views/:viewId" element={<WaterfallPage />} />
          </Route>

          <Route path="insights" element={<InsightsPage />} />
          <Route path="initiatives">
            <Route index element={<InitiativeListPage />} />
            <Route path=":initiativeId" element={<InitiativePage />} />
          </Route>
          <Route path="virtual-dimensions">
            <Route index element={<VirtualDimensionsPage />} />
            <Route
              path="job-executions"
              element={<JobExecutionsPage type="update" />}
            />
            <Route path="new" element={<NewVirtualDimensionPage />} />
            <Route
              path=":virtualDimensionId"
              element={<EditVirtualDimensionPage />}
            />
            <Route
              path=":virtualDimensionId/draft/:draftId"
              element={<EditVirtualDimensionVersion />}
            />
          </Route>
          <Route path="data-health" element={<DataHealthPage />} />
          <Route path="digest" element={<DigestPage />} />
          <Route path="digests">
            <Route index element={<ListPageWrapper />} />
            <Route path="new/:period/:datePreset" element={<NewDigest />} />
            <Route path="edit/:digestId" element={<EditDigest />} />
          </Route>
          <Route path="datasources" element={<DatasourcesPage />} />
          <Route path="labels-explorer" element={<LabelsExplorerPage />} />
          <Route
            path="setup-executions"
            element={<JobExecutionsPage type="full_refresh" />}
          />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="events" element={<EventsPage />} />
          <Route path="metrics" element={<MetricsPage />} />
          <Route path="tags" element={<TagsPage />} />
          <Route path="saved-views" element={<SavedViewsPage />} />
          <Route path="mapper" element={<VdimHelperPage />} />
          <Route path="alerts">
            <Route index element={<AlertsListPage />} />
            <Route path="new" element={<AlertCreationPage />} />
            <Route path=":alertId/edit" element={<AlertEditionPage />} />
          </Route>
          <Route path="budgets" element={<ForceColorScheme />}>
            <Route index element={<BudgetsListPage />} />
            <Route path="new" element={<BudgetCreationPage />} />
            <Route path=":budgetId">
              <Route index element={<BudgetPage />} />
              <Route path="edit" element={<BudgetEditionPage />} />
              <Route path="new" element={<BudgetRevisionPage />} />
            </Route>
          </Route>
          <Route path="integration" element={<IntegrationPage />} />
          <Route path="slack/redirect" element={<SlackOAuthRedirect />} />
        </Route>
      </Route>
    </Route>,
  ),
)
