import {
  Button,
  Group,
  Modal,
  Stack,
  Title,
  Text,
  Tooltip,
  ActionIcon,
} from "@mantine/core"
import { Dropzone, FileWithPath } from "@mantine/dropzone"
import {
  IconFile,
  IconJson,
  IconPencil,
  IconUpload,
  IconX,
} from "@tabler/icons-react"
import { reject } from "lodash"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import zodToJsonSchema from "zod-to-json-schema"

import {
  editVirtualDimensionSchema,
  VirtualDimensionsResponses,
} from "@costory/types/endpoints/virtualDimensions"

import { VirtualDimensionDraftCSVExportButton } from "@costory/front/pages/VirtualDimensions/components/ButtonDownloadJson"
import { useCreateDraftVirtualDimensionMutation } from "@costory/front/queries/virtualDimensions"
import { downloadBlob } from "@costory/front/utils/downloadBlob"
import { getDefaultTableOptions } from "@costory/front/utils/table"

export function ModalOpenVersions({
  opened,
  onClose,
  data,
}: {
  opened: boolean
  onClose: () => void
  data: VirtualDimensionsResponses.VirtualDimension["VirtualDimensionVersions"]
}) {
  const navigate = useNavigate()
  const columns = useMemo(() => {
    const columnHelper =
      createMRTColumnHelper<
        VirtualDimensionsResponses.VirtualDimension["VirtualDimensionVersions"][0]
      >()
    return [
      columnHelper.accessor("description", {
        header: "Description",
        filterVariant: "autocomplete",
      }),
      columnHelper.accessor("createdBy.firstName", {
        header: "Created By",
        filterVariant: "autocomplete",
        Cell: ({ row }) =>
          `${row.original.createdBy.firstName} ${row.original.createdBy.lastName}`,
      }),
      columnHelper.accessor("createdAt", {
        header: "Created At",
        filterVariant: "autocomplete",
      }),
      columnHelper.accessor("isCompleted", {
        header: "Status",
        Cell: ({ cell }) => (cell.getValue() ? "Live" : "Draft"),
      }),
      columnHelper.display({
        header: "Actions",
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Group wrap="nowrap">
            <Button
              size="xs"
              variant="outline"
              leftSection={<IconPencil size={16} />}
              onClick={() =>
                navigate(
                  `/virtual-dimensions/${row.original.virtualDimensionId}/draft/${row.original.id}`,
                )
              }
            >
              Open
            </Button>
            <VirtualDimensionDraftCSVExportButton
              variant="compact"
              virtualDimensionDraftId={row.original.id}
            />
          </Group>
        ),
      }),
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const table = useMantineReactTable<
    VirtualDimensionsResponses.VirtualDimension["VirtualDimensionVersions"][0]
  >({
    columns,
    data,
    ...getDefaultTableOptions(),
    initialState: {
      sorting: [{ id: "createdAt", desc: true }],
    },
  })
  const getJsonSchema = () => {
    const jsonSchema = zodToJsonSchema(
      editVirtualDimensionSchema,
      "CostoryVdimSchema",
    )
    const blob = new Blob([JSON.stringify(jsonSchema)], {
      type: "application/schema+json",
    })
    downloadBlob(blob, "costory_vdim.schema.json")
  }
  const mutation = useCreateDraftVirtualDimensionMutation()

  const openJson = (files: FileWithPath[]) => {
    const fileReader = new FileReader()
    fileReader.onload = (event) => {
      if (event.target) {
        const parsedData = JSON.parse(event.target.result as string)
        mutation.mutateAsync({
          data: parsedData,
          virtualDimensionId: data[0].virtualDimensionId,
        })
      }
    }

    fileReader.onerror = (error) => reject(error)
    fileReader.readAsText(files[0])
  }
  if (data) {
    return (
      <Modal opened={opened} onClose={onClose} size="100%">
        <Stack>
          <Group justify="space-between">
            <Title> All Versions</Title>
            <Group>
              <Dropzone
                maxFiles={1}
                onDrop={openJson}
                onReject={(files) => console.log("rejected files", files)}
                maxSize={5 * 1024 ** 2}
                accept={["application/json"]}
              >
                <Group
                  justify="center"
                  gap="xl"
                  mih={8}
                  style={{ pointerEvents: "none" }}
                >
                  <Dropzone.Accept>
                    <IconUpload
                      size={30}
                      color="var(--mantine-color-blue-6)"
                      stroke={1.5}
                    />
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <IconX
                      size={30}
                      color="var(--mantine-color-red-6)"
                      stroke={1.5}
                    />
                  </Dropzone.Reject>
                  <Dropzone.Idle>
                    <IconFile
                      size={30}
                      color="var(--mantine-color-dimmed)"
                      stroke={1.5}
                    />
                  </Dropzone.Idle>

                  <div>
                    <Text size="xl" inline>
                      Import Draft Version
                    </Text>
                  </div>
                </Group>
              </Dropzone>
              <Tooltip label="Download JSON Schema Validation">
                <ActionIcon onClick={getJsonSchema}>
                  <IconJson />
                </ActionIcon>
              </Tooltip>
            </Group>
          </Group>

          <MantineReactTable table={table} />
        </Stack>
      </Modal>
    )
  }
}
