import {
  ComboboxData,
  Group,
  Select,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core"
import { DatePickerInput, DatesRangeValue } from "@mantine/dates"
import { UseFormReturnType } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { IconHelpCircle } from "@tabler/icons-react"
import { useFeatureFlag } from "configcat-react"
import _ from "lodash"
import { useCallback, useState } from "react"

import {
  AGG_BY_OPTIONS,
  CurrencyOptions,
  DatePreset,
  Filters,
} from "@costory/types/filters"

import { DEFAULT_DATE_FORMAT } from "@costory/shared/const"
import dayjs from "@costory/shared/dayjs"
import {
  computeComparisonPeriodDates,
  computePresetDates,
  getDatePresetOptions,
  LIMIT_OPTIONS,
} from "@costory/shared/utils/filters"

import { QueryBuilder } from "@costory/front/components/QueryBuilder"
import { ModalCurrency } from "@costory/front/components/charts/ModalCurrency"
import { TagsSearch } from "@costory/front/pages/Events"

type Props = {
  form: UseFormReturnType<Filters>
  metricsOptions: ComboboxData
  groupByOptions: ComboboxData
  queryError: boolean
  drawerMode?: boolean
}

export const FilterBar = ({
  form,
  metricsOptions,
  groupByOptions,
  queryError,
  drawerMode = false,
}: Props) => {
  const [dateRange, setDateRange] = useState<DatesRangeValue>([
    form.getValues().from,
    form.getValues().to,
  ])

  const handleSelectDateRange = useCallback(
    (dateRange: DatesRangeValue) => {
      const [fromDate, toDate] = dateRange
      setDateRange([fromDate, toDate])

      // So date values match the ones from z.coerce.date()
      const from = fromDate ? toUTCDate(dayjs(fromDate).toDate()) : null
      const to = toDate ? toUTCDate(dayjs(toDate).toDate()) : null

      if (from && to) {
        form.setValues({
          from,
          to,
          datePreset: null,
          ...computeComparisonPeriodDates(from, to, null),
        })
      }
    },
    [form],
  )

  const handleSelectDatePreset = useCallback(
    (selectedPreset: string | null) => {
      if (!selectedPreset) return
      const presetDates = computePresetDates(selectedPreset as DatePreset)
      setDateRange([presetDates.from, presetDates.to])
      form.setValues(presetDates)
    },
    [form],
  )
  const toUTCDate = (date: Date | null | undefined): Date | undefined => {
    if (!date) return undefined
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    )
  }

  const { previousFrom, previousTo, datePreset } = form.getValues()
  const datePickerTooltip =
    dateRange[0] && dateRange[1]
      ? `Comparing to ${datePreset ? _.startCase(datePreset) : "PREVIOUS PERIOD"}: ${dayjs(previousFrom).local().format(DEFAULT_DATE_FORMAT)} - ${dayjs(previousTo).local().format(DEFAULT_DATE_FORMAT)}`
      : "No Date Range Selected"
  const [openedModalCurrency, handlersModalCurrency] = useDisclosure()
  const featureFlagEvents = useFeatureFlag("events", false)
  return (
    <>
      <ModalCurrency
        opened={openedModalCurrency}
        close={handlersModalCurrency.close}
      />
      <Stack>
        <Group align="flex-end">
          {!drawerMode && (
            <Select
              w={70}
              label="Limit"
              placeholder="Select limit"
              allowDeselect={false}
              data={LIMIT_OPTIONS}
              {...form.getInputProps("limit")}
              key={form.key("limit")}
              value={form.getValues().limit.toString()}
              onChange={(value) =>
                value ? form.setFieldValue("limit", parseInt(value)) : null
              }
            />
          )}

          <>
            <Select
              w={90}
              label={
                <Text>
                  Currency
                  <Tooltip label="Show currency exchange rates used">
                    <ThemeIcon c="primary.6" mt={2} size="sm">
                      <IconHelpCircle
                        onClick={() => handlersModalCurrency.open()}
                      />
                    </ThemeIcon>
                  </Tooltip>
                </Text>
              }
              placeholder="Currency"
              allowDeselect={false}
              data={CurrencyOptions}
              {...form.getInputProps("currency")}
              key={form.key("currency")}
            />
            <Select
              w={180}
              label="Metric"
              placeholder="Select metric"
              allowDeselect={false}
              data={metricsOptions}
              {...form.getInputProps("metricId")}
              key={form.key("metricId")}
            />{" "}
          </>

          <Select
            w={100}
            label="Aggregate By"
            placeholder="Select aggregation"
            allowDeselect={false}
            data={AGG_BY_OPTIONS}
            {...form.getInputProps("aggBy")}
            key={form.key("aggBy")}
          />
          <Select
            w={250}
            label="Group By"
            placeholder="Select group by"
            allowDeselect={false}
            data={groupByOptions}
            searchable
            {...form.getInputProps("groupBy")}
            key={form.key("groupBy")}
          />
          <Tooltip label={datePickerTooltip}>
            <Group align="flex-end" gap={0}>
              <DatePickerInput
                valueFormat="YYYY MMM DD"
                label="Dates"
                allowSingleDateInRange={false}
                placeholder="Select time period"
                type="range"
                w={220}
                value={dateRange}
                onChange={handleSelectDateRange}
                styles={{
                  input: {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                }}
              />
              <Select
                w={150}
                data={getDatePresetOptions()}
                placeholder="Select date preset"
                {...form.getInputProps("datePreset")}
                key={form.key("datePreset")}
                onChange={handleSelectDatePreset}
                styles={{
                  input: {
                    borderLeft: 0,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  },
                }}
              />
            </Group>
          </Tooltip>
          {featureFlagEvents.value && (
            <TagsSearch
              label="Select Events"
              mw="400px"
              tags={form.getValues().eventsTagsSearch || []}
              setTags={form.getInputProps("eventsTagsSearch").onChange}
              key={form.key("eventsTagsSearch")}
            />
          )}
        </Group>
        {!drawerMode && (
          <QueryBuilder
            key={form.key("whereClause")}
            defaultValue={form.getValues().whereClause}
            queryError={queryError}
            {...form.getInputProps("whereClause")}
          />
        )}
      </Stack>
    </>
  )
}
