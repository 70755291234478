import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { BudgetTable } from "@costory/front/pages/Budgets/components/BudgetTable"
import { BudgetTableMode } from "@costory/front/pages/Budgets/consts"
import { useBudgetFormDataQuery } from "@costory/front/queries/budgets"

export const BudgetCreationPage = () => (
  <QueryWrapper query={useBudgetFormDataQuery()}>
    {({ data }) => (
      <BudgetTable formData={data} mode={BudgetTableMode.Create} />
    )}
  </QueryWrapper>
)
