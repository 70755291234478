import { Button, Group, TextInput, Stack, Select } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { showNotification } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import _ from "lodash"
import { useMemo } from "react"

import {
  datasourceSchema,
  DatasourcesRequests,
  DatasourcesResponses,
} from "@costory/types/endpoints/datasources"

import { apiClient } from "@costory/front/lib/apiClient"
import { MetadataForm } from "@costory/front/pages/Datasources/components/MetadataForm"
import { DEFAULT_DATASOURCES } from "@costory/front/pages/Datasources/components/constants"

type Props = {
  datasource?: DatasourcesResponses.Datasource
  onSave: (datasource: DatasourcesRequests.EditDatasource) => void
  isSaving: boolean
}

export const DatasourceForm = ({ datasource, onSave, isSaving }: Props) => {
  const initialValues = useMemo(() => {
    if (datasource) {
      const out = datasourceSchema.parse(datasource)
      return out
    }
    return {
      name: "New Datasource",
      type: DEFAULT_DATASOURCES[3].type,
      connector: DEFAULT_DATASOURCES[3].connector,
      metadata: DEFAULT_DATASOURCES[3].metadata,
    }
  }, [datasource])

  const datasourceTypeOptions = useMemo(() => {
    return _.map(
      _.groupBy(DEFAULT_DATASOURCES, (d) => d.group),
      (datasources, group) => ({
        group,
        items: datasources.map((d) => ({
          value: d.type,
          label: "label" in d ? d.label : d.connector,
        })),
      }),
    )
  }, [])

  const handleSelectType = (datasourceType: string | null) => {
    if (!datasourceType) return

    const datasource = DEFAULT_DATASOURCES.find(
      (d) => d.type === datasourceType,
    )!

    form.setFieldValue("type", datasource.type)
    form.setFieldValue("connector", datasource.connector)
    // @ts-expect-error TODO: probably fixable, but dunno why now
    form.setFieldValue("metadata", datasource.metadata)
  }

  const form = useForm<DatasourcesRequests.EditDatasource>({
    initialValues: initialValues as DatasourcesRequests.EditDatasource,
    validate: zodResolver(datasourceSchema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
    mode: "uncontrolled",
  })
  const mutation = useMutation({
    mutationFn: async (form: DatasourcesRequests.EditDatasource) => {
      const response = await apiClient.post<DatasourcesResponses.Status>(
        "/datasources/test",
        form,
      )
      return response.data
    },
    onSuccess: async (data) => {
      showNotification({
        color: data.status === "SUCCESS" ? "green" : "red",
        title: data.status,
        message: data.message,
      })
    },
    onError: (error) => {
      showNotification({
        color: "red",
        title: "Error",
        message: error.message,
      })
    },
  })
  return (
    <form onSubmit={form.onSubmit(onSave)}>
      <Stack>
        <TextInput label="Name of connector" {...form.getInputProps("name")} />
        <Select
          label="Datasource Type"
          data={datasourceTypeOptions}
          {...form.getInputProps("type")}
          onChange={(e) => handleSelectType(e)}
        />
        <MetadataForm form={form} />
        <Group justify="flex-end" mt={8}>
          <Button
            loading={mutation.isPending}
            type="button"
            onClick={() => mutation.mutateAsync(form.getValues())}
          >
            Test
          </Button>
          <Button loading={isSaving} type="submit">
            Save
          </Button>
        </Group>
      </Stack>
    </form>
  )
}
