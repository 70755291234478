import {
  Group,
  InputWrapper,
  Paper,
  Select,
  Stack,
  TextInput,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import dayjs from "dayjs"

import {
  InitiativeDetails,
  InitiativesRequests,
  InitiativesResponses,
} from "@costory/types/endpoints/initiatives"
import {
  InitiativeEffort,
  InitiativeSnoozeType,
  InitiativeStatus,
} from "@costory/types/prisma-client"

import LiveBlocks from "@costory/front/components/Room"
import { SavingsOverview } from "@costory/front/pages/Initiatives/SavingsOverview"
import { useAuthState } from "@costory/front/queries/auth"
import { PropsWithData } from "@costory/front/utils/propsWithData"

interface InitiativeFormProps
  extends PropsWithData<InitiativesResponses.GetFormData> {
  form: UseFormReturnType<InitiativesRequests.CreateInitiative>
  existingInitiative?: InitiativeDetails
  isReadOnly?: boolean
}

export const InitiativeForm = ({
  data: { availableOwners, savedView },
  form,
  existingInitiative,
  isReadOnly = false,
}: InitiativeFormProps) => {
  const auth = useAuthState()

  const availableStatuses = Object.values(InitiativeStatus).map((status) => ({
    label: status,
    value: status,
  }))

  const availableEfforts = Object.values(InitiativeEffort).map((effort) => ({
    label: effort,
    value: effort,
  }))

  // const availableSnoozeTypes = Object.values(InitiativeSnoozeType).map(
  //   (type) => ({
  //     label: type === InitiativeSnoozeType.NoSnooze ? "No snooze" : type,
  //     value: type,
  //   }),
  // )
  const roomId = savedView
    ? auth.user!.currentOrg.slug + ":" + savedView?.id //+ ":" + redirectPage
    : undefined
  const formValues = form.getValues()
  console.log("FORM VALUES", formValues)

  return (
    <Stack align="stretch" gap={8}>
      <Paper component={Stack}>
        <Group align="start" wrap="nowrap">
          <InputWrapper label="Initiative name" required>
            <TextInput
              readOnly={isReadOnly}
              flex={1}
              placeholder="Initiative name"
              {...form.getInputProps("name")}
            />
          </InputWrapper>
          <InputWrapper label="Effort" required>
            <Select
              allowDeselect={false}
              readOnly={isReadOnly}
              data={availableEfforts}
              placeholder="Select effort"
              {...form.getInputProps("effort")}
            />
          </InputWrapper>
          <InputWrapper label="Status" required>
            <Select
              allowDeselect={false}
              readOnly={isReadOnly}
              data={availableStatuses}
              placeholder="Select status"
              {...form.getInputProps("status")}
            />
          </InputWrapper>
          <InputWrapper label="Owner">
            <Select
              readOnly={isReadOnly}
              data={availableOwners}
              placeholder="No owner"
              {...form.getInputProps("ownerId")}
            />
          </InputWrapper>
          <InputWrapper label="Ticket link">
            <TextInput
              readOnly={isReadOnly}
              placeholder="jira.com/example-id"
              {...form.getInputProps("ticketUrl")}
            />
          </InputWrapper>
        </Group>
        <Group align="start">
          <InputWrapper label="Description" flex={1}>
            <TextInput
              readOnly={isReadOnly}
              placeholder="Description"
              {...form.getInputProps("description")}
            />
          </InputWrapper>
        </Group>
      </Paper>
      {/* <Group wrap="nowrap">
          <InputWrapper label="Snooze trigger">
            <Select
              allowDeselect={false}
              readOnly={isReadOnly}
              data={availableSnoozeTypes}
              placeholder="Select a trigger type"
              {...form.getInputProps("snoozeType")}
            />
          </InputWrapper>
          {formValues.snoozeType === InitiativeSnoozeType.Date ? (
            <InputWrapper label="Snooze until">
              <DateInput
                placeholder="Snooze date"
                readOnly={isReadOnly}
                {...form.getInputProps("snoozeDate")}
              />
            </InputWrapper>
          ) : (
            <InputWrapper label="Snooze until">
              <NumberInput
                disabled={
                  formValues.snoozeType === InitiativeSnoozeType.NoSnooze
                }
                readOnly={isReadOnly}
                allowNegative={false}
                placeholder={
                  formValues.snoozeType === InitiativeSnoozeType.Savings
                    ? "Monthly savings "
                    : "Monthly cost"
                }
                {...form.getInputProps("snoozeValue")}
              />
            </InputWrapper>
          )}
        </Group> */}

      {/* <Group align="stretch" h="50%"> */}
      <Stack flex={1.5}>
        <SavingsOverview
          form={form}
          isReadOnly={isReadOnly}
          savedViewId={savedView.id}
          startDate={formValues.startDate}
          createdAt={
            existingInitiative
              ? existingInitiative.createdAt
              : dayjs().utc().toDate()
          }
          expectedSavings={formValues.expectedSavings}
          snoozeDate={
            formValues.snoozeType === InitiativeSnoozeType.Date
              ? (formValues.snoozeDate ?? null)
              : null
          }
          snoozeValue={
            formValues.snoozeType === InitiativeSnoozeType.Cost
              ? (formValues.snoozeValue ?? null)
              : null
          }
        />
      </Stack>
      {/* </Group> */}
      {existingInitiative && <LiveBlocks roomId={roomId} />}
    </Stack>
  )
}
