import { MONTHS_OF_THE_YEAR } from "@costory/shared/const"
import dayjs from "@costory/shared/dayjs"

import { Th } from "@costory/front/pages/Budgets/components/Th"
import {
  mediumGrayBorder,
  mediumPrimaryBorder,
} from "@costory/front/pages/Budgets/consts"

const bigBlackBorder = "3px solid var(--mantine-color-black)"

interface BudgetTableHeadeProps {
  monthsOffset: number
}

export const BudgetTableHead = ({ monthsOffset }: BudgetTableHeadeProps) => {
  const offsetMonthsOfTheYear = MONTHS_OF_THE_YEAR.map(
    (month) => (month + monthsOffset) % MONTHS_OF_THE_YEAR.length,
  )
  return (
    <thead
      style={{
        position: "sticky",
        borderBottom: "1px solid var(--mantine-color-black)",
        zIndex: 1,
        top: 0,
      }}
    >
      <tr style={{ borderBottom: bigBlackBorder }}>
        <Th
          colSpan={3}
          style={{
            textAlign: "center",
            borderRight: mediumGrayBorder,
          }}
        >
          Virtual dimension value
        </Th>
        {offsetMonthsOfTheYear.map((month, index) => (
          <Th
            key={month}
            style={{
              textAlign: "center",
              ...(month === offsetMonthsOfTheYear.length - 1 && {
                borderRight: mediumPrimaryBorder,
              }),
              ...(index === offsetMonthsOfTheYear.length - 1 && {
                borderRight: mediumGrayBorder,
              }),
            }}
          >
            {dayjs().month(month).format("MMM")}
          </Th>
        ))}
        <Th
          style={{
            textAlign: "center",
            borderRight: mediumGrayBorder,
          }}
        >
          YTD
        </Th>
        <Th
          style={{
            textAlign: "center",
            borderRight: mediumGrayBorder,
          }}
        >
          Total
        </Th>
        <Th
          style={{
            borderRight: "none",
            width: 150,
            textAlign: "center",
          }}
        >
          Owner
        </Th>
      </tr>
    </thead>
  )
}
