import Intercom from "@intercom/messenger-js-sdk"
import { HmacSHA256 } from "crypto-js"

import { useAuthState } from "@costory/front/queries/auth"

export default function NativeIntercom() {
  const auth = useAuthState()
  const userIdentifier = auth.user?.email
  const userHash = userIdentifier
    ? HmacSHA256(
        userIdentifier,
        import.meta.env.VITE_INTERCOM_SECRET,
      ).toString()
    : ""

  Intercom({
    app_id: import.meta.env.VITE_INTERCOM_WORKSPACE_ID,
    user_id: auth.user?.email,
    name: `${auth.user?.firstName} ${auth.user?.lastName}`,
    email: auth.user?.email,
    userHash,
  })

  return null
}
