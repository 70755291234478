import {
  Button,
  Checkbox,
  CopyButton,
  Flex,
  Textarea,
  Tooltip,
} from "@mantine/core"
import { useEffect } from "react"

import { SearchAcrossValuesSelection } from "../types"

interface FooterProps {
  onInsert: (celString: string) => void
  not: boolean
  setNot: (not: boolean) => void
  currentSelection: SearchAcrossValuesSelection
  generatedCELRule: string
}

export const Footer = ({
  currentSelection,
  not,
  setNot,
  onInsert,
  generatedCELRule,
}: FooterProps) => {
  const disabled = !currentSelection.length
  useEffect(() => {
    if (currentSelection.length === 0) {
      // Reset the Not checkbox when the selection is empty again
      setNot(false)
    }
  }, [setNot, currentSelection.length])

  return (
    <footer>
      <Textarea
        autosize
        label="Generated CEL Rule"
        value={generatedCELRule}
        style={{ marginBottom: 10 }}
      />
      <Flex gap="sm" align="center" justify="flex-end">
        <Tooltip.Group openDelay={500} closeDelay={100}>
          <Tooltip label="Invert the condition generated in the CEL rule">
            <Checkbox
              disabled={disabled}
              label="Not"
              checked={not}
              onChange={() => setNot(!not)}
            />
          </Tooltip>

          <CopyButton value={generatedCELRule}>
            {({ copied, copy }) => (
              <Tooltip label="Copy the CEL rule">
                <Button disabled={disabled} variant="light" onClick={copy}>
                  {copied ? "Copied rule" : "Copy Rule"}
                </Button>
              </Tooltip>
            )}
          </CopyButton>
          <Tooltip label="Insert the CEL rule at the current cursor position">
            <Button
              disabled={disabled}
              onClick={() => onInsert(generatedCELRule)}
            >
              Insert Rule
            </Button>
          </Tooltip>
        </Tooltip.Group>
      </Flex>
    </footer>
  )
}
