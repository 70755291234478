export type FocusCols =
  | "cos_service_name"
  | "cos_sku"
  | "cos_sub_account_id"
  | "cos_cluster_name"
  | "cos_namespace"
  | "cos_workload_name"
  | "cos_workload_type"
  | string

export function getBestDrillDown(currentGroupBy: FocusCols): FocusCols {
  switch (currentGroupBy) {
    case "cos_service_name": {
      return "cos_sku"
    }
    case "cos_sub_account_id": {
      return "cos_service_name"
    }
    case "cos_cluster_name":
      return "cos_namespace"
    case "cos_namespace":
      return "cos_workload_name"
    case "cos_workload_name":
      return "cos_workload_type"
    default: {
      return "cos_sub_account_id"
    }
  }
}
