import { Protect } from "@clerk/clerk-react"
import { UnstyledButton, NavLink, ThemeIcon, Tooltip } from "@mantine/core"
import { useHover } from "@mantine/hooks"
import { useFeatureFlag, User } from "configcat-react"
import { FC } from "react"
import { NavLink as BaseNavLink } from "react-router-dom"

import { SidebarItem } from "@costory/front/components/layout/constants"
import { Permissions } from "@costory/front/utils/permissions"

type Props = {
  route: SidebarItem
  isCollapsed: boolean
  user?: User
  isParent?: boolean
}

export const SidebarLink: FC<Props> = ({
  route,
  user,
  isCollapsed,
  isParent = false,
}) => {
  const currentPath = window.location.pathname.replace("/", "")
  const shouldBeOpened =
    route.links &&
    route.links
      .map((el) => el.path)
      .filter((el) => !!el)
      .includes(currentPath)
  const isFF = "isFeatureFlagged" in route
  const { ref, hovered } = useHover()

  const { value: isEnabled, loading } = useFeatureFlag(
    isFF ? route.path! : "alwaysvalid",
    false,
    user,
  )

  if (isFF && (loading || !isEnabled)) {
    return (
      // empty component otherwise the hover breaks
      <UnstyledButton hidden />
    )
  }
  return (
    <Protect permission={route.role || Permissions.Member}>
      <Tooltip
        label={route.tooltip}
        disabled={!route.tooltip}
        position="right"
        offset={24}
      >
        <NavLink
          ref={ref}
          leftSection={
            <ThemeIcon c="inherit" size="sm">
              {route.icon && <route.icon />}
            </ThemeIcon>
          }
          style={{
            color: !hovered && isParent ? "white" : "gray",
            background: route.links ? "transparent" : undefined,
          }}
          to={route.path || "#"}
          component={BaseNavLink}
          autoContrast
          key={route.name}
          defaultOpened={shouldBeOpened}
          opened={isCollapsed ? false : undefined}
          label={route.name}
        >
          {route.links &&
            route.links.map((el, index) => (
              <SidebarLink
                route={el}
                isCollapsed={isCollapsed}
                key={index}
                user={user}
              />
            ))}
        </NavLink>
      </Tooltip>
    </Protect>
  )
}
