import { Text, Accordion, Center, Group, Tooltip, Loader } from "@mantine/core"
import {
  IconPlugConnected,
  IconPlugConnectedX,
  TablerIcon,
} from "@tabler/icons-react"
import { ReactNode } from "react"

type Props = {
  name: string
  icon: TablerIcon
  isConnected: boolean
  isLoading?: boolean
  children: ReactNode
}

export const IntegrationAccordionItem = ({
  name,
  icon: Icon,
  isConnected,
  isLoading = false,
  children,
}: Props) => {
  return (
    <Accordion.Item value={name}>
      <Accordion.Control
        disabled={isLoading}
        icon={
          isLoading ? (
            <Loader size="xs" color="black" />
          ) : (
            <Tooltip label={isConnected ? "Connected" : "Not Connected"}>
              <Center>
                {isConnected ? <IconPlugConnected /> : <IconPlugConnectedX />}
              </Center>
            </Tooltip>
          )
        }
      >
        <Group>
          <Icon />
          <Text size="md">{name}</Text>
        </Group>
      </Accordion.Control>
      <Accordion.Panel>{children}</Accordion.Panel>
    </Accordion.Item>
  )
}
