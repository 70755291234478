import { useQuery } from "@tanstack/react-query"

import { MetricsResponses } from "@costory/types/endpoints/metrics"

import { apiClient } from "@costory/front/lib/apiClient"

export const useBusinessMetricsQuery = () => {
  return useQuery({
    queryKey: ["metrics"],
    queryFn: async () => {
      const response =
        await apiClient.get<MetricsResponses.Metric[]>("/metrics")
      return response.data
    },
  })
}

export const useBusinessMetricValueQuery = (
  metricId: string,
  virtualDimensionValueId: string,
  startDate: Date,
) => {
  return useQuery({
    queryKey: ["metrics", metricId, "cost", startDate],
    queryFn: async () => {
      if (!metricId) {
        return undefined
      }
      const response = await apiClient.post<MetricsResponses.GetMetricCost>(
        `/metrics/${metricId}/cost`,
        { virtualDimensionValueId, startDate },
      )

      return response.data
    },
  })
}
