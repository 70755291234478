import { Modal, TextInput, Group, Button, Grid, Textarea } from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { useForm } from "@mantine/form"
import { useMemo } from "react"

import {
  eventSchema,
  EventsRequests,
  EventsResponses,
} from "@costory/types/endpoints/events"

import dayjs from "@costory/shared/dayjs"

import { useUser } from "@costory/front/components/auth/UserContext"
import { TagsSearch } from "@costory/front/pages/Events"

type Props = {
  data?: EventsResponses.List
  isOpen: boolean
  onClose: () => void
  onSave: (event: EventsRequests.Event) => void
  defaultDate?: Date
}

export const EventsEditModal = ({
  data,
  isOpen,
  onClose,
  onSave,
  defaultDate,
}: Props) => {
  const user = useUser()
  const initialValues = useMemo(() => {
    if (data) {
      return eventSchema.parse(data)
    }
    return {
      name: "",
      description: "",
      date: defaultDate || dayjs().subtract(1, "D").toDate(),
      tags: [
        { key: "source", value: "manual" },
        { key: "createdBy", value: `${user.firstName}_${user.lastName}` },
      ],
      metadata: {},
    }
  }, [data, defaultDate, user.firstName, user.lastName])
  const toUTCDate = (date: Date | null | undefined): Date | undefined => {
    if (!date) return undefined
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    )
  }
  const form = useForm<EventsRequests.Event>({
    initialValues,
    validateInputOnBlur: true,
    validateInputOnChange: true,
    transformValues(values) {
      const dateinUTC = toUTCDate(values.date)
      return { ...values, date: dateinUTC! }
    },
  })
  return (
    <Modal
      size="xl"
      opened={isOpen}
      onClose={() => {
        form.reset()
        onClose()
      }}
      title="Event"
    >
      <form onSubmit={form.onSubmit(onSave)}>
        <Grid gutter="md">
          <Grid.Col span={12}>
            <Grid gutter="md">
              <Grid.Col>
                <TextInput label="Name" {...form.getInputProps("name")} />
              </Grid.Col>
              <Grid.Col>
                <Textarea
                  label="Description"
                  {...form.getInputProps("description")}
                />
              </Grid.Col>
              <Grid.Col>
                <DatePickerInput label="Date" {...form.getInputProps("date")} />
              </Grid.Col>
              <Grid.Col>
                <TagsSearch
                  tags={form.getValues().tags}
                  setTags={form.getInputProps("tags").onChange}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>

        <Group justify="flex-end" mt="md">
          <Button type="submit">Submit</Button>
        </Group>
      </form>
    </Modal>
  )
}
