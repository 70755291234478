export const bigBlackBorder = "3px solid var(--mantine-color-black)"
export const smallGrayBorder = "1px solid var(--mantine-color-gray-3)"
export const mediumGrayBorder = "1px solid var(--mantine-color-gray-7)"
export const mediumPrimaryBorder = "2px solid var(--mantine-color-primary-4)"

export enum BudgetTableMode {
  View = "view",
  Edit = "edit",
  Create = "create",
  Revision = "revision",
}
