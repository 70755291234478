import { useQuery } from "@tanstack/react-query"

import { AxesResponses } from "@costory/types/endpoints/axes"

import { apiClient } from "@costory/front/lib/apiClient"

export const useAxesQuery = () => {
  return useQuery({
    queryKey: ["axes"],
    queryFn: async () => {
      const response = await apiClient.get<AxesResponses.Axis[]>("/axes")
      return response.data
    },
  })
}

export const useAxesMapperQuery = () => {
  return useQuery({
    queryKey: ["axes", "mapper"],
    queryFn: async () => {
      const response = await apiClient.get<AxesResponses.Axis[]>("/axes")
      const map = new Map<string, string>()
      response.data.forEach((el) => map.set(el.name, el.label))
      return map
    },
  })
}
