import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react"

import { User } from "@costory/types/user"

type UserContextType = {
  user: User
  updateUser: Dispatch<SetStateAction<User>>
}

export const UserContext = createContext<UserContextType>({
  // @ts-expect-error Creating this context to have an absolute to a defined user object.
  // Context shall not be used if user doesn't exist
  user: undefined,
  updateUser: (user) => user,
})

type UserProviderProps = {
  user: User
  children: ReactNode
}

export const UserProvider = ({ user, children }: UserProviderProps) => {
  const [currentUser, setCurrentUser] = useState(user)

  return (
    <UserContext.Provider
      value={{ user: currentUser, updateUser: setCurrentUser }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (!context?.user) {
    throw new Error("useUser must be used under AuthGuard-wrapped routes")
  }
  return context.user
}
