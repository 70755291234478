import { showNotification } from "@mantine/notifications"
import _ from "lodash"
import { useCallback, useMemo } from "react"
import { useSearchParams } from "react-router-dom"

import { AxesResponses } from "@costory/types/endpoints/axes"
import { SavedViewsResponses } from "@costory/types/endpoints/savedViews"
import { Filters, FiltersValidator } from "@costory/types/filters"

import dayjs from "@costory/shared/dayjs"
import { createSafeParsedFilters } from "@costory/shared/utils/filters"

import { orderComponentTypes } from "@costory/front/utils/dashboards"

export type DatasourceType =
  | "Datasources"
  | "Virtual Dimensions"
  | "Invoice"
  | "Native labels"

// TODO: test it all
export const orderDatasources = (value: DatasourceType) => {
  switch (value) {
    case "Datasources": {
      return 1
    }
    case "Invoice": {
      return 3
    }
    case "Virtual Dimensions": {
      return 2
    }
    case "Native labels": {
      return 4
    }
    default: {
      return 5
    }
  }
}

export const formatDatasources = (axes: AxesResponses.Axis[]) => {
  return _.sortBy(
    _.map(
      _.groupBy(axes, (axis) => axis.groupTitle),
      (groupAxes, group) => ({
        group,
        items: groupAxes.map((axis) => ({
          value: axis.name,
          label: axis.label,
        })),
      }),
    ),
    (option) => orderDatasources(option.group as DatasourceType),
  )
}

export const formatSavedViews = (
  savedViews: SavedViewsResponses.SavedView[],
) => {
  return _(savedViews)
    .groupBy("category")
    .map((v, k) => ({
      group: k,
      items: v.map((sv) => ({
        value: sv.id,
        label: sv.name,
      })),
    }))
    .sortBy((g) => orderComponentTypes(g.group))
    .value()
}

export const DEFAULT_QUERY_BUILDER_FILTER = { combinator: "and", rules: [] }
export const DEFAULT_QUERY_BUILDER_FILTER_RECCURING = {
  combinator: "and",
  rules: [
    { field: "cos_charge_frequency", operator: "notIn", value: ["One-Time"] },
  ],
}

export const useFiltersSearchParamsBinding = (
  onValidateFilters: FiltersValidator["safeParse"],
) => {
  const [searchParams, setURLSearchParams] = useSearchParams()

  const urlFilters = useMemo<Filters | undefined>(() => {
    if (!searchParams.get("metricId")) return undefined

    const searchParamsObj = Object.fromEntries(searchParams)

    // TODO: Need to add a proper fix for the dates between timezone issue
    // FixMe: https://github.com/costory-io/costory-app/issues/508
    const parsedSearchParams = {
      datePreset: null,
      ...searchParamsObj,
      from: dayjs(searchParamsObj.from).toDate(),
      to: dayjs(searchParamsObj.to).toDate(),
      previousFrom: dayjs(searchParamsObj.previousFrom).toDate(),
      previousTo: dayjs(searchParamsObj.previousTo).toDate(),
      whereClause: JSON.parse(searchParamsObj.whereClause),
      eventsTagsSearch: searchParamsObj.eventsTagsSearch
        ? JSON.parse(searchParamsObj.eventsTagsSearch)
        : [],
    }
    const validatedSearchParams = onValidateFilters(parsedSearchParams)
    if (!validatedSearchParams.success) {
      showNotification({
        color: "red",
        title: "Invalid URL parameters",
        message:
          "The filters set in the URL are invalid. Please check your browser console for more information. Default filter values will be used.",
      })
      console.log(
        "ERROR validating URL filters",
        validatedSearchParams.error.issues,
      )
      return undefined
    }

    return validatedSearchParams.data
  }, [searchParams, onValidateFilters])

  const setURLFilters = useCallback(
    (filters: Filters | null) => {
      if (filters === null) {
        setURLSearchParams("")
      }
      const safeParsedFilters = createSafeParsedFilters(filters)
      setURLSearchParams(new URLSearchParams(safeParsedFilters))
    },
    [setURLSearchParams],
  )

  return { urlFilters, setURLFilters }
}
