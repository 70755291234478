import { Drawer, Select, Stack } from "@mantine/core"
import { useQuery } from "@tanstack/react-query"
import { useMemo, useState } from "react"

import { AxesResponses } from "@costory/types/endpoints/axes"
import { DigestRequests } from "@costory/types/endpoints/digest"
import { Filters } from "@costory/types/filters"

import { computePresetDates } from "@costory/shared/utils/filters"

import { Chart } from "@costory/front/components/charts/Chart"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { apiClient } from "@costory/front/lib/apiClient"
import { getReactQueryBuilderQuery } from "@costory/front/pages/DigestV2/helper"
import { formatDatasources } from "@costory/front/utils/filters"

type Props = {
  opened: boolean
  close: () => void
  position: { key: string; value: string }[]
  period: DigestRequests.Period
}

type PropsPrefillExplorer = {
  axes: AxesResponses.Axis[]
  position: { key: string; value: string }[]
  period: DigestRequests.Period
}

const PrefillExplorerUI = ({
  axes,
  position,
  period,
}: PropsPrefillExplorer) => {
  const whereClause = position.map((elem) => getReactQueryBuilderQuery(elem))
  const [groupBy, setGroupBy] = useState("cos_provider")
  const groupByOptions = useMemo(() => formatDatasources(axes), [axes])

  const filters: Filters = {
    metricId: "cost",
    currency: "USD",
    limit: 10,
    groupBy,
    ...computePresetDates(
      period === "MonthToMonth"
        ? "LAST_MONTH"
        : period === "MTD"
          ? "MTD"
          : "LAST_3_MONTHS",
    ),
    aggBy: "Day",
    whereClause: { combinator: "AND", rules: whereClause },
  }

  return (
    <>
      <Stack>
        <Select
          w={300}
          label="Group By"
          value={groupBy}
          onChange={(e) => setGroupBy(e!)}
          placeholder="Select group by"
          allowDeselect={false}
          data={groupByOptions}
          searchable
          key="groupBy"
        />
        <Chart chartType="TREND" filters={filters} height={500} />
      </Stack>
    </>
  )
}

export const ExplorerDrawer = ({ opened, close, position, period }: Props) => {
  const axesQuery = useQuery({
    queryKey: ["axes"],
    queryFn: async () => {
      const response = await apiClient.get<AxesResponses.Axis[]>("/axes")
      return response.data
    },
  })

  return (
    <>
      <Drawer
        size="xl"
        opened={opened}
        onClose={close}
        title="Explore Cost"
        position="right"
      >
        <QueryWrapper query={axesQuery}>
          {({ data: axes }) => (
            <PrefillExplorerUI
              axes={axes}
              position={position}
              period={period}
            />
          )}
        </QueryWrapper>
      </Drawer>
    </>
  )
}
