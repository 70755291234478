import { createContext } from "react"

import { BudgetsResponses } from "@costory/types/endpoints/budgets"
import { Currency } from "@costory/types/prisma-client"

import { BudgetTableMode } from "@costory/front/pages/Budgets/consts"

export interface BudgetTableContextValue {
  formData: BudgetsResponses.BudgetFormData
  mode: BudgetTableMode
  currency: Currency
}
export const BudgetTableContext = createContext<BudgetTableContextValue | null>(
  null,
)
