import { EventsRequests } from "@costory/types/endpoints/events"

import { EventsEditModal } from "@costory/front/pages/Events/EventsForm"
import { useCreateNewEventMutation } from "@costory/front/queries/events"

export const NewEventForm = ({
  isOpen,
  onClose,
  defaultDate = new Date(),
}: {
  isOpen: boolean
  onClose: () => void
  defaultDate?: Date
}) => {
  const { mutateAsync: createEvent } = useCreateNewEventMutation(onClose)

  const onSave = async (event: EventsRequests.Event) => {
    await createEvent(event)
  }

  return (
    <EventsEditModal
      onSave={onSave}
      isOpen={isOpen}
      onClose={onClose}
      defaultDate={defaultDate}
    />
  )
}
