import { Paper, Stack, Title } from "@mantine/core"
import { IconBriefcaseOff } from "@tabler/icons-react"

export const EmptyBudgetTable = () => (
  <Paper h="60vh" styles={{ root: { overflowY: "scroll" } }} p={0}>
    <Stack align="center" mt="20vh">
      <IconBriefcaseOff color="var(--mantine-color-primary-6)" size={60} />
      <Title order={3}>Select a virtual dimension to start</Title>
    </Stack>
  </Paper>
)
