import { Menu, ActionIcon, Tooltip } from "@mantine/core"
import { IconSearch } from "@tabler/icons-react"

import { DigestResponses } from "@costory/types/endpoints/digest"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { getOptions, NodeTree } from "@costory/front/pages/DigestV2/helper"
import { useAxesMapperQuery } from "@costory/front/queries/axes"
import { formatNumber } from "@costory/front/utils/format"

export const ButtonAddTo = ({
  addChild,
  total,
  digest,
  node,
  disabled,
}: {
  addChild: (direction: string) => void
  node?: NodeTree
  total?: number
  digest: DigestResponses.Investigation
  disabled?: boolean
}) => {
  // What is the best suggestion ?
  const queryMapper = useAxesMapperQuery()
  const options = getOptions(digest, node ? node.nodeProps.position : [])
  const suggested = options.filter(
    (elem) =>
      elem.nbrValue > 1 && (total ? total * 0.7 <= elem.totalDiff : true),
  )
  const discouraged = options.filter(
    (elem) =>
      !(elem.nbrValue > 1 && (total ? total * 0.7 <= elem.totalDiff : true)),
  )

  return (
    <QueryWrapper query={queryMapper}>
      {(translateColumnNames) => (
        <>
          <Menu shadow="md" width="max-content">
            <Tooltip label="Zoom Into Line">
              <Menu.Target>
                <ActionIcon
                  variant="transparent"
                  aria-label="Settings"
                  disabled={disabled}
                >
                  <IconSearch
                    style={{ width: "70%", height: "70%" }}
                    stroke={1.5}
                  />
                </ActionIcon>
              </Menu.Target>
            </Tooltip>

            <Menu.Dropdown>
              <Menu.Label> Suggested </Menu.Label>
              {suggested.map((elem) => (
                <Menu.Item
                  onClick={() => addChild(elem.direction)}
                  key={elem.direction}
                >
                  {translateColumnNames.data.get(elem.direction) ||
                    elem.direction}
                  : {elem.nbrValue} :{formatNumber(elem.totalDiff)}
                </Menu.Item>
              ))}
              <Menu.Label> Other axes </Menu.Label>
              {discouraged.map((elem) => (
                <Menu.Item
                  onClick={() => addChild(elem.direction)}
                  key={elem.direction}
                >
                  {translateColumnNames.data.get(elem.direction) ||
                    elem.direction}
                  : {elem.nbrValue} :{formatNumber(elem.totalDiff)}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        </>
      )}
    </QueryWrapper>
  )
}
