import { useParams } from "react-router-dom"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { DigestForm } from "@costory/front/pages/DigestV2/DigestForm"
import {
  useEditDigestMutation,
  useQueryOpenDigest,
} from "@costory/front/queries/digest"

export function EditDigest() {
  const { digestId } = useParams<{ digestId: string }>()
  const mutation = useEditDigestMutation(digestId!)
  const query = useQueryOpenDigest(digestId!)
  return (
    <QueryWrapper query={query}>
      {({ data }) => (
        <DigestForm
          initialValues={data}
          onSave={(d) => mutation.mutateAsync(d)}
        />
      )}
    </QueryWrapper>
  )
}
