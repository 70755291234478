import { useParams } from "react-router-dom"

import { DatePresetDigest } from "@costory/types/prisma-client"

import { DigestForm } from "@costory/front/pages/DigestV2/DigestForm"
import { useNewDigestMutation } from "@costory/front/queries/digest"

export function NewDigest() {
  const mutation = useNewDigestMutation()
  const { period, datePreset } = useParams<{
    period: string
    datePreset: string
  }>()
  return (
    <DigestForm
      initialValues={{
        datePreset: datePreset! as DatePresetDigest,
        period: period!,
      }}
      onSave={(d) => mutation.mutateAsync(d)}
    />
  )
}
