import { Table, UnstyledButton } from "@mantine/core"
import { forwardRef } from "react"

import type {
  SearchAcrossColumnsAndValuesResult,
  SearchAcrossValuesSelection,
  SearchAcrossValuesSelectionItem,
} from "../types"

import { ColumnCheckbox } from "./ColumnCheckbox"
import { ValueCheckbox } from "./ValueCheckbox"

interface ColumValueTreeProps {
  items: SearchAcrossColumnsAndValuesResult
  currentSelection: SearchAcrossValuesSelection
  setCurrentSelection: (newSelection: SearchAcrossValuesSelection) => void
}

const ColumValueTree = forwardRef<
  HTMLDivElement,
  ColumValueTreeProps & React.ComponentPropsWithoutRef<"div">
>(({ items, currentSelection, setCurrentSelection }, ref) => {
  const onValueCheck = (value: string, column: string) => {
    const newItem: SearchAcrossValuesSelectionItem = {
      type: "value",
      column,
      value,
    }
    const newSelection = [...currentSelection, newItem]
    setCurrentSelection(newSelection)
  }
  const onValueUncheck = (value: string, column: string) => {
    const newSelection = currentSelection?.filter(
      (item) =>
        !(
          item.type === "value" &&
          item.column === column &&
          item.value === value
        ),
    )
    setCurrentSelection(newSelection)
  }
  const onColumnCheck = (column: string) => {
    const newItem: SearchAcrossValuesSelectionItem = {
      type: "column",
      column,
    }
    const newSelection = [...currentSelection, newItem]
    setCurrentSelection(newSelection)
  }
  const onColumnUncheck = (column: string) => {
    const newSelection = currentSelection?.filter(
      (item) => !(item.type === "column" && item.column === column),
    )
    setCurrentSelection(newSelection)
  }

  const ClearSelection = () => {
    return currentSelection.length ? (
      <UnstyledButton
        onClick={() => setCurrentSelection([])}
        style={{
          fontSize: 10,
          textDecoration: "underline",
          color: "var(--mantine-color-primary-6)",
        }}
      >
        Clear selection
      </UnstyledButton>
    ) : null
  }

  const body =
    items.length === 0 ? (
      <Table.Tr>
        <Table.Td colSpan={2} style={{ textAlign: "center" }}>
          No results found
        </Table.Td>
      </Table.Tr>
    ) : (
      items.map((item) => {
        return (
          <Table.Tr key={item.field}>
            <Table.Td width="250" style={{ padding: "10px 0" }} valign="top">
              <ColumnCheckbox
                label={item.label}
                field={item.field}
                currentSelection={currentSelection}
                onCheck={onColumnCheck}
                onUncheck={onColumnUncheck}
              />
            </Table.Td>
            <Table.Td width="350" style={{ padding: "10px 0" }} valign="top">
              <ul
                style={{
                  margin: 0,
                  padding: 0,
                  listStyle: "none",
                  wordBreak: "break-word",
                }}
              >
                {item.values.map((value) => {
                  return (
                    <li key={value} style={{ margin: "0 0 5px" }}>
                      <ValueCheckbox
                        label={value}
                        name={value}
                        column={item.field}
                        currentSelection={currentSelection}
                        onCheck={onValueCheck}
                        onUncheck={onValueUncheck}
                      />
                    </li>
                  )
                })}
              </ul>
            </Table.Td>
          </Table.Tr>
        )
      })
    )

  return (
    <div ref={ref}>
      <Table striped stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Column name</Table.Th>
            <Table.Th
              style={{ display: "flex", gap: "3px", alignItems: "center" }}
            >
              Values <ClearSelection />
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{body}</Table.Tbody>
      </Table>
    </div>
  )
})

ColumValueTree.displayName = "ColumValueTree"

export { ColumValueTree }
export type { ColumValueTreeProps }
