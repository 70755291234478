import { showNotification } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"

import {
  EventsRequests,
  EventsResponses,
} from "@costory/types/endpoints/events"

import { apiClient } from "@costory/front/lib/apiClient"
import { queryClient } from "@costory/front/lib/queryClient"

export function useCreateNewEventMutation(closeModal: () => void) {
  return useMutation({
    mutationFn: async (form: EventsRequests.Event) => {
      const response = await apiClient.post<EventsResponses.New>(
        "/events/new",
        form,
      )
      return response.data
    },
    onSuccess: async () => {
      showNotification({
        color: "green",
        title: "Events Created",
        message: "",
      })
      await queryClient.invalidateQueries({ queryKey: ["events"] })
      closeModal()
    },
    onError: (error) => {
      showNotification({
        color: "red",
        title: "Error",
        message: error.message,
      })
    },
  })
}
export function useEditEventMutation(id: string, closeModal: () => void) {
  return useMutation({
    mutationFn: async (form: EventsRequests.Event) => {
      const response = await apiClient.put<EventsResponses.New>(
        `/events/${id}`,
        form,
      )
      return response.data
    },
    onSuccess: async () => {
      showNotification({
        color: "green",
        title: "Events Updated",
        message: "",
      })
      await queryClient.invalidateQueries({ queryKey: ["events"] })
      closeModal()
    },
    onError: (error) => {
      showNotification({
        color: "red",
        title: "Error",
        message: error.message,
      })
    },
  })
}

export const useTagsQuery = () => {
  return useQuery({
    queryKey: ["events", "tags"],
    queryFn: async () => {
      const response = await apiClient.get<EventsResponses.Tags>("/events/tags")
      return response.data
    },
  })
}

export function useDeleteEventMutation() {
  return useMutation({
    mutationFn: async (id: string) => {
      const response = await apiClient.delete<EventsResponses.New>(
        `/events/${id}`,
      )
      return response.data
    },
    onSuccess: async () => {
      showNotification({
        color: "green",
        title: "Events Archived",
        message: "",
      })
      await queryClient.invalidateQueries({ queryKey: ["events"] })
    },
    onError: (error) => {
      showNotification({
        color: "red",
        title: "Error",
        message: error.message,
      })
    },
  })
}
