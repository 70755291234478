import { Loader, Stack, Title } from "@mantine/core"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import { useUser } from "@costory/front/components/auth/UserContext"

export const SlackOAuthRedirect = () => {
  const user = useUser()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    searchParams.set("orgId", user.currentOrg.id)
    window.location.replace(
      `${import.meta.env.VITE_API_URL}/slack/redirect?${searchParams.toString()}`,
    )
  }, [searchParams, user.currentOrg.id])

  return (
    <Stack align="center" gap={32}>
      <Title>Finalizing Slack App Installation...</Title>
      <Loader />
    </Stack>
  )
}
