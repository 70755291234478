import _ from "lodash"

import { Currency } from "@costory/types/prisma-client"

import { formatNumber } from "@costory/front/utils/format"

export interface FormatedTdProps
  extends Omit<React.HTMLProps<HTMLTableCellElement>, "data" | "children"> {
  data?: number | null
  currency?: Currency
  formatForDelta?: boolean
}

export const FormatedTd = ({
  data,
  currency,
  formatForDelta = false,
  ...props
}: FormatedTdProps) => {
  const formatedData = _.isNumber(data)
    ? formatNumber(data, currency ? "currency" : "decimal", undefined, currency)
    : "-"

  return (
    <td
      {...props}
      style={{
        padding: 4,
        textAlign: "center",
        color:
          formatForDelta && _.isNumber(data)
            ? data < 0
              ? "red"
              : "green"
            : "black",
        ...props.style,
      }}
    >
      {formatedData}
    </td>
  )
}
