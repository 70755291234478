import { Button, Group, Stack, Title, Tooltip } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { openConfirmModal } from "@mantine/modals"
import { IconPencil, IconPlus, IconTrash } from "@tabler/icons-react"
import {
  createMRTColumnHelper,
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"

import { DigestResponses } from "@costory/types/endpoints/digest"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { DateCell } from "@costory/front/components/table/DateCell"
import { UserCell } from "@costory/front/components/table/UserCell"
import { CreateDigestModal } from "@costory/front/pages/DigestV2/CreateModale"
import {
  useDeleteDigestMutation,
  useDigestListQuery,
} from "@costory/front/queries/digest"

type Props = {
  digests: DigestResponses.ListDigests[]
}

const ListPage = ({ digests }: Props) => {
  const navigate = useNavigate()
  const mutationDelete = useDeleteDigestMutation()
  const columns = useMemo<MRT_ColumnDef<DigestResponses.ListDigests>[]>(() => {
    const columnHelper = createMRTColumnHelper<DigestResponses.ListDigests>()
    return [
      columnHelper.accessor("name", {
        header: "Name",
      }),
      columnHelper.accessor("period", {
        header: "Period",
      }),
      columnHelper.accessor("datePreset", {
        header: "Preset",
      }),
      columnHelper.accessor("createdBy", {
        header: "Created By",
        Cell: ({ cell }) => <UserCell user={cell.getValue()} />,
      }),
      columnHelper.accessor("updatedAt", {
        header: "Updated At",
        Cell: ({ cell }) => <DateCell date={cell.getValue()} />,
      }),
      columnHelper.display({
        header: "Actions",
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Group gap={8} wrap="nowrap">
            <Tooltip label="Edit">
              <Button
                size="xs"
                variant="outline"
                leftSection={<IconPencil size={16} />}
                onClick={() => {
                  navigate(`edit/${row.original.id}`)
                }}
              >
                Edit
              </Button>
            </Tooltip>
            <Tooltip label="Delete">
              <Button
                size="xs"
                variant="outline"
                color="red"
                leftSection={<IconTrash size={16} />}
                onClick={() =>
                  openConfirmModal({
                    title: <Title size="h3">Archive this digest</Title>,
                    labels: { confirm: "Confirm", cancel: "Cancel" },
                    onConfirm: () => {
                      mutationDelete.mutateAsync(row.original.id)
                    },
                  })
                }
              >
                Delete
              </Button>
            </Tooltip>
          </Group>
        ),
      }),
    ]
  }, [mutationDelete, navigate])

  const table = useMantineReactTable<DigestResponses.ListDigests>({
    columns,
    data: digests,
  })
  const [openedNewModal, handlersNewModal] = useDisclosure()
  return (
    <>
      <CreateDigestModal
        opened={openedNewModal}
        close={handlersNewModal.close}
      />

      <Stack gap={32}>
        <Group justify="flex-end">
          <Button
            onClick={() => handlersNewModal.open()}
            leftSection={<IconPlus />}
          >
            New
          </Button>
        </Group>
        <MantineReactTable table={table} />
      </Stack>
    </>
  )
}

export const ListPageWrapper = () => {
  const digestsQuery = useDigestListQuery()
  return (
    <QueryWrapper query={digestsQuery} allowEmptyArray>
      {({ data }) => <ListPage digests={data} />}
    </QueryWrapper>
  )
}
