import { Checkbox, Tooltip } from "@mantine/core"

import { SearchAcrossValuesSelection } from "../types"

interface ColumnCheckboxProps {
  label: string
  field: string
  currentSelection: SearchAcrossValuesSelection
  onCheck: (column: string) => void
  onUncheck: (column: string) => void
}

export const ColumnCheckbox = ({
  label,
  field,
  currentSelection,
  onCheck,
  onUncheck,
}: ColumnCheckboxProps) => {
  const isCurrentlySelected = !!currentSelection.find(
    (item) => item.type === "column" && item.column === field,
  )

  return (
    <Tooltip label={field} refProp="rootRef" openDelay={300}>
      <Checkbox
        disabled={currentSelection.length > 0 && !isCurrentlySelected}
        label={label}
        name={field}
        checked={isCurrentlySelected}
        onChange={(e) => {
          if (e.currentTarget.checked) {
            onCheck(field)
          } else {
            onUncheck(field)
          }
        }}
      />
    </Tooltip>
  )
}
