import {
  ActionIcon,
  Button,
  Group,
  Pill,
  Stack,
  TagsInput,
} from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { useDisclosure } from "@mantine/hooks"
import { modals } from "@mantine/modals"
import { IconEdit, IconSearch, IconTrash } from "@tabler/icons-react"
import { useQuery } from "@tanstack/react-query"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { useMemo, useState } from "react"

import { EventsBody, EventsResponses } from "@costory/types/endpoints/events"

import { DEFAULT_DATE_FORMAT } from "@costory/shared/const"
import dayjs from "@costory/shared/dayjs"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { apiClient } from "@costory/front/lib/apiClient"
import { NewEventForm } from "@costory/front/pages/Events/NewEventForm"
import { EditEventForm } from "@costory/front/pages/Events/UpdateEvent"
import {
  useDeleteEventMutation,
  useTagsQuery,
} from "@costory/front/queries/events"
import { getDefaultTableOptions } from "@costory/front/utils/table"

type Props = {
  eventsList: EventsResponses.List[]
}

const EventsList = ({ eventsList }: Props) => {
  const [eventSelected, setSelectedEvent] = useState<EventsResponses.List>()
  const [editEventOpened, handlersEditEvent] = useDisclosure(false)
  const deleteMutation = useDeleteEventMutation()

  const columns = useMemo(() => {
    const columnHelper = createMRTColumnHelper<EventsResponses.List>()
    return [
      columnHelper.accessor("name", {
        header: "name",
        filterVariant: "select",
      }),
      columnHelper.accessor("description", {
        header: "Description",
      }),
      columnHelper.accessor("tags", {
        header: "Tags",
        Cell: ({ cell }) => (
          <Group>
            {cell
              .getValue()
              .map((el) => `${el.key}:${el.value}`)
              .map((el, index) => (
                <Pill key={index}> {el}</Pill>
              ))}
          </Group>
        ),
      }),
      columnHelper.accessor("date", {
        header: "Date",
        filterVariant: "select",
        Cell: ({ cell }) => (
          <>{dayjs(cell.getValue()).format(DEFAULT_DATE_FORMAT)}</>
        ),
      }),
    ]
  }, [])

  const table = useMantineReactTable<EventsResponses.List>({
    columns,
    data: eventsList,
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row }) => (
      <Group>
        <ActionIcon
          onClick={() => {
            setSelectedEvent(row.original)
            handlersEditEvent.open()
          }}
        >
          <IconEdit />
        </ActionIcon>
        <ActionIcon
          onClick={() =>
            modals.openConfirmModal({
              title: "Are you sure?",
              confirmProps: { color: "red" },
              labels: { cancel: "Cancel", confirm: "Delete" },
              onConfirm: () => deleteMutation.mutateAsync(row.original.id),
            })
          }
        >
          <IconTrash />
        </ActionIcon>
      </Group>
    ),

    ...getDefaultTableOptions(),
  })
  return (
    <>
      {editEventOpened && (
        <EditEventForm
          isOpen={editEventOpened}
          onClose={handlersEditEvent.close}
          data={eventSelected!}
        />
      )}

      <Stack>
        <MantineReactTable table={table} />
      </Stack>
    </>
  )
}

export const EventsPage = () => {
  const [tags, setTags] = useState<EventsBody["tags"]>([])
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    new Date("2024-01-01"),
    new Date(),
  ])
  const [newEventOpened, handlersNewEvent] = useDisclosure(false)

  const toUTCDate = (date: Date | null | undefined): Date | undefined => {
    if (!date) return undefined
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    )
  }
  const eventsQuery = useQuery({
    queryKey: ["events", tags, dateRange],
    queryFn: async () => {
      const response = await apiClient.post<EventsResponses.List[]>("/events", {
        tags,
        startDate: toUTCDate(dateRange[0]),
        endDate: toUTCDate(dateRange[1]),
      })
      return response.data
    },
  })

  return (
    <>
      <Stack>
        <Group justify="space-between">
          <Group>
            <IconSearch />
            <TagsSearch tags={tags} setTags={setTags} />
            <DatePickerInput
              type="range"
              value={dateRange}
              onChange={setDateRange}
            />
          </Group>

          <Button onClick={handlersNewEvent.open}>Create event </Button>
        </Group>

        {newEventOpened && (
          <NewEventForm
            isOpen={newEventOpened}
            onClose={handlersNewEvent.close}
          />
        )}

        <QueryWrapper query={eventsQuery} allowEmptyArray>
          {({ data }) => <EventsList eventsList={data} />}
        </QueryWrapper>
      </Stack>
    </>
  )
}

export const TagsSearch = ({
  tags,
  setTags,
  size = "sm",
  label,
  mw,
}: {
  tags: EventsBody["tags"]
  setTags: (tags: EventsBody["tags"]) => void
  size?: string
  label?: string | undefined
  mw?: string
}) => {
  const query = useTagsQuery()
  const formatter = (data: { key: string; value: string }[]) =>
    data.map((el) => `${el.key}:${el.value}`)
  return (
    <>
      <QueryWrapper query={query} allowEmptyArray>
        {({ data }) => (
          <>
            <TagsInput
              style={{ maxWidth: mw }}
              label={label}
              size={size}
              data={formatter(data)}
              value={formatter(tags)}
              clearable
              placeholder="tags"
              onChange={(d) =>
                setTags(
                  d
                    .map((el) => el.split(":"))
                    .filter((el) => el.length == 2)
                    .map((el) => ({ key: el[0], value: el[1] })),
                )
              }
            />
          </>
        )}
      </QueryWrapper>
    </>
  )
}
