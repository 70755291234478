import { showNotification } from "@mantine/notifications"
import { useNavigate, useParams } from "react-router-dom"

import { useUser } from "@costory/front/components/auth/UserContext"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { BudgetTable } from "@costory/front/pages/Budgets/components/BudgetTable"
import { BudgetTableMode } from "@costory/front/pages/Budgets/consts"
import {
  useBudgetFormDataQuery,
  useGetBudgetQuery,
} from "@costory/front/queries/budgets"

export const BudgetRevisionPage = () => {
  const { budgetId } = useParams<{ budgetId: string }>()
  const formDataQuery = useBudgetFormDataQuery()
  const budgetQuery = useGetBudgetQuery(budgetId!)
  const navigate = useNavigate()
  const user = useUser()

  return (
    <QueryWrapper query={budgetQuery}>
      {({ data: { versions } }) => {
        if (versions[0].createdBy.id !== user.id) {
          showNotification({
            title: "Unauthorized",
            message: "You are not allowed to edit this budget",
            color: "red",
          })
          navigate("/budgets")
          return null
        }
        return (
          <QueryWrapper query={formDataQuery}>
            {({ data }) => (
              <BudgetTable
                formData={data}
                versions={versions}
                mode={BudgetTableMode.Revision}
              />
            )}
          </QueryWrapper>
        )
      }}
    </QueryWrapper>
  )
}
