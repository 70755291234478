import { useQuery } from "@tanstack/react-query"
import _ from "lodash"

import {
  EventsRequests,
  EventsResponses,
} from "@costory/types/endpoints/events"
import {
  ExploreRequests,
  ExploreResponses,
} from "@costory/types/endpoints/explorer"
import {
  VirtualDimensionsRequests,
  VirtualDimensionsResponses,
} from "@costory/types/endpoints/virtualDimensions"
import { Filters } from "@costory/types/filters"

import { apiClient } from "@costory/front/lib/apiClient"

export const useExplorerQuery = (filters: Filters) => {
  const filtersWithoutNonExplorer = _.omit(filters, ["eventsTagsSearch"])
  return useQuery({
    queryKey: [
      "charts",
      "explorer",
      filters.metricId,
      filtersWithoutNonExplorer,
    ],
    queryFn: async () => {
      const response = await apiClient.post<ExploreResponses.ExplorerData>(
        "/explore/explorer",
        filters,
      )
      return response.data
    },
  })
}
export const useEventsQuery = (filters: Filters) => {
  const searchParams: EventsRequests.Search = {
    startDate: filters.from,
    endDate: filters.to,
    aggBy: filters.aggBy,
    tags: filters.eventsTagsSearch ? filters.eventsTagsSearch : undefined,
  }
  return useQuery({
    queryKey: [
      "events",
      "search",
      filters.aggBy,
      filters.from,
      filters.to,
      filters.eventsTagsSearch,
    ],
    queryFn: async () => {
      const response = await apiClient.post<EventsResponses.Aggregated[]>(
        "/events/aggregate",
        searchParams,
      )
      return response.data
    },
  })
}

export const useWaterfallQuery = (filters: Filters) => {
  const filtersWithoutNonExplorer = _.omit(filters, ["eventsTagsSearch"])
  return useQuery({
    queryKey: [
      "charts",
      "waterfall",
      filters.metricId,
      filtersWithoutNonExplorer,
    ],
    queryFn: async () => {
      const response = await apiClient.post<
        ExploreRequests.WaterfallData,
        { data: ExploreResponses.Waterfall }
      >("/explore/waterfall", filters)
      return response.data
    },
  })
}

export const useExplorerDrawer = (
  data: VirtualDimensionsRequests.SheetExplorerData,
) => {
  return useQuery({
    queryKey: ["virtual-dimensions", "prefill", data],
    queryFn: async () => {
      const response = await apiClient.post<
        VirtualDimensionsRequests.SheetExplorerData,
        { data: VirtualDimensionsResponses.SheetExplorerData }
      >("/virtual-dimensions/explore-cost", data)
      return response.data
    },
  })
}
