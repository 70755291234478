export const DEFAULT_DATASOURCES = [
  {
    group: "Billing",
    connector: "GCP",
    type: "gcp/detailed_billing",
    metadata: {
      axes: [] as string[],
    },
  },
  {
    group: "Billing",
    connector: "AWS",
    type: "aws/cur",
    metadata: {
      axes: [] as string[],
    },
  },
  {
    group: "Billing",
    connector: "AWS",
    label: "AWS v2",
    type: "aws/cur_v2",
    metadata: {},
  },
  {
    group: "Billing",
    connector: "Azure",
    type: "azure/actual",
    metadata: {
      axes: [] as string[],
    },
  },
  {
    group: "Business Metrics",
    connector: "GSheet",
    type: "gsheet/business_metrics",
    metadata: {
      metrics: ["metric1", "metric2"],
      periodRange: "month",
      email: "",
    },
  },
  {
    group: "Integrations",
    connector: "GSheetCurrency",
    type: "gsheet/currency",
    metadata: {
      email: "",
    },
  },
  {
    group: "Integrations",
    connector: "S3Bucket",
    type: "aws/s3_bucket",
    metadata: {},
  },
  {
    group: "Decorators",
    connector: "DecoratorBigQuery",
    type: "decorator/bigquery",
    metadata: {},
  },
  {
    group: "Billing",
    connector: "Datadog",
    type: "datadog",
    metadata: {},
  },
] as const
